import * as requestFromServer from "./Crud";
import {appSlice, callTypes} from "./Slice";

const {actions} = appSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      // console.log("Server response is", response.data);
      if (type === 'categories') {
        // var caz = [];
        // response.data.results.forEach((ele) => {
        //   if (!ele.parent) {
        //     caz.push(ele);
        //   }
        // })
        // console.log(response.data);
        dispatch(actions.allCategory(response.data.results));
        // dispatch(actions.allCategory(response.data));
      } if (type === 'products') {
        // console.log(response.data);
        // dispatch(actions.allCategory(response.results));
        return response.data;
      } if (type === 'order') {
        dispatch(actions.allOrders(response.data.results));
        return response.data;
      } 
      
      // const { totalCount, entities } = response.data;
      // dispatch(actions.DatasFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchData = id => dispatch => {
  if (!id) {
    return dispatch(actions.DataFetched({ DataForEdit: undefined }));
  }

  // dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  //   .getDataById(id)
  //   .then(response => {
  //     const Data = response.data;
  //     dispatch(actions.DataFetched({ DataForEdit: Data }));
  //   })
  //   .catch(error => {
  //     error.clientMessage = "Can't find Data";
  //     dispatch(actions.catchError({ error, callType: callTypes.action }));
  //   });
};


export const write_data = (type, data) => dispatch => {
  if (type === 'first_name') {
    dispatch(actions.setFirstName(data));
  } else if (type === 'last_name') {
    dispatch(actions.setLastName(data));
  } else if (type === 'email') {
    dispatch(actions.setEmail(data));
  } else if (type === 'tel') {
    dispatch(actions.setTel(data));
  } else if (type === 'event_type') {
    dispatch(actions.setEventType(data));
  } else if (type === 'guests') {
    dispatch(actions.setGuests(data));
  } else if (type === 'budget') {
    dispatch(actions.setBudget(data));
  } else if (type === 'selected') {
    dispatch(actions.setSelected(data));
  } else if (type === 'products') {
    dispatch(actions.setProducts(data));
  } else if (type === 'stock') {
    // dispatch(actions.setProducts(data));
    // dispatch(actions.allOrders(data));
       
  }
};


export const deleteData = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteData(id)
    .then(response => {
      dispatch(actions.DataDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addData = (DataForCreation, query, type = 'cart') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewData(DataForCreation, query, type)
    .then(response => {
      if (type === 'cart') {
        // console.log(response)
        dispatch(actions.disableLoad());
        return response;
      } else if (type === 'remove') {
        // console.log(response)
        dispatch(actions.disableLoad());
        return response;
      } else if (type === 'email' || type === 'email2') {
        // console.log(response)
        dispatch(actions.disableLoad());
        return response;
      }
      // const { Data } = response.data;
      // dispatch(actions.DataCreated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateData = Data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateData(Data)
    .then(() => {
      dispatch(actions.DataUpdated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDatasStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForDatas(ids, status)
    .then(() => {
      dispatch(actions.DatasStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Datas status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDatas = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDatas(ids)
    .then(() => {
      dispatch(actions.DatasDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Datas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
