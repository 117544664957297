import React, { useState, useEffect } from "react"


import Loader from "../../../components/loader";
import Product from "../../../components/product";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/app/Actions";
import * as functions from "../../../global/functions";

import InfiniteScroll from 'react-infinite-scroll-component';

import { useStateWithCallbackLazy } from 'use-state-with-callback';

import { useMediaQuery } from 'react-responsive';

import config from "../../../config";

import { withRouter } from 'react-router';



export default function TabData(props) {
  const dispatch = useDispatch();

  // console.log("Props", props); return;

  const current = props.selected;
  

  const name = functions.capitalizeFirstLetter(current.name);
  const [products, set_products] = useState([]);
  const [search, set_search] = useState('');
  const [page, set_page] = useStateWithCallbackLazy(1);
  const PAGE_SIZE = 10;
  // const [search, set_search] = useState('');

  const [isFetching, setIsFetching] = useState(false);
  
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [showSearch, setShow] = useState(false);
  

  // const [has_more, set_has_more] = useState(true);

  var has_more = true;
  
  const [uuid, set_uuid] = useState('');


  // useEffect(() => {
  //   console.log(props.products)
  // }, [props.products])

 
  useEffect(() => {


    // console.log("Selected", props.selected)
    has_more = true;
    set_products([]);
    


    fetchMoreData(1);


  }, [props.selected])


  function fetchMoreData(page_no = 1) {
    // alert(page_no);
    // alert("calling func")
    // var cat = props.categories;
    var ind = props.categories.findIndex(x => x.name.toLowerCase() === current.name);
    // console.log("sdcsadvsadcvasdca", ind, current.name)
    if (!has_more) {
      setIsFetching(false);
      return;
    }
    if (ind && ind !== -1) {
      // console.log(ind)
      setIsFetching(true);
      set_uuid(props.categories[ind].uuid);
      dispatch(actions.fetchAllData('products', `?category__uuid=${props.categories[ind].uuid}&page_size=${PAGE_SIZE}&page=${page_no}`)).then((data) => {
        // console.log("DDDDDD", data); return;
        if (data.results.length > 0) {
          setIsFetching(false);
          set_page(page_no+1)
          // set_products(data.results)
          set_products(prevState => ([...prevState, ...data.results]));
        }
      }).catch((error) => {
        // console.log(error);
        // set_has_more(false);
        has_more = false;
        setIsFetching(false);
      })
    } else {
      setIsFetching(true);
      set_uuid('');
      dispatch(actions.fetchAllData('products', `?page_size=${PAGE_SIZE}&page=${page_no}`)).then((data) => {
        if (data.results) {
          setIsFetching(false);
          set_page(page_no+1)
          set_products(prevState => ([...prevState, ...data.results]));
        }
      }).catch((error) => {
        // console.log(error);
        has_more = false;
        // set_has_more(false);
        setIsFetching(false);
      })
    }
  }



  function search_daata() {
    if (search !== '') {
      dispatch(actions.fetchAllData('products', uuid !== '' ? `?category__uuid=${uuid}&search=${search}` : `?search=${search}`)).then((data) => {
        set_products(data.results);
      })
    }

  }



  

  return (
    <>
   
   <div class="col-xl-9 col-lg-12">
              <div class="row">
                <div class="col-12">
                  <div class="top--filter mb-0">
                    <div
                      class="mobile-side-bar d-sm-grid d-md-grid d-lg-grid d-xl-none"
                    >
                      <div class="btn-sidebar-container">
                        <button
                          type="button"
                          class="btn btn-outline btn-label"
                          data-bs-toggle="modal"
                          data-bs-target="#filter-modal-large"
                        >
                          <i class="icon icon-filter"></i>
                          {name}
                          <span class="filter--nav__cat_qty"
                            >20 <small>BTL</small></span
                          >
                        </button>
                      </div>
                    </div>

                    <h4 class="top--filter__title title mb-0">{name}</h4>
                    <span class="top--filter__cat--price">
                      <small class="label">Category Total</small>
                      <span class="price">{props.cat_cost}</span>
                    </span>

                    <span class="hr-divider"></span>

                    <span class="top--filter__ctn-qty">
                      <i class="icon icon-carton-of-drinks top"></i>
                      <span class="top--filter__ctn-qty-holder">
                        <small class="label">Cartons</small>
                        <span class="qty">100</span>
                      </span>
                    </span>

                    <span class="top--filter__btl-qty">
                      <i class="icon icon-wine top"></i>
                      <span class="top--filter__ctn-qty-holder">
                        <small class="label">BTL</small>
                        <span class="qty">{props.bottle_no}</span>
                      </span>
                    </span>

                    <form
                      class={`top--filter__cat_search search float_btn ${showSearch ? '' : 'd-none'} d-sm-flex d-md-flex d-lg-flex d-xl-flex`}
                      onSubmit={(e) => {
                        search_daata()
                        e.preventDefault();
                      }}
                    >
                      <label
                        ><input
                          placeholder={`Search in ${name}`}
                          type=""
                          value = {search}
                          onChange = {(e) => {
                            set_search(e.target.value);
                          }}
                          class="form-control sm" />
                          <button 
                            onClick = {() => {
                              // search_daata()
                              if (isMobile) {
                                setShow(false)
                              }
                            }}
                          >
                          {
                            showSearch ? <i class="icon icon-close"></i> : <i class="icon icon-search"></i>
                          }
                          </button></label>
                    </form>

                    {/* <form
                      class="top--filter__cat_sort d-none d-xl-flex d-lg-flex"
                    >
                      <label class="select-sm inline-select"
                        >Sort
                        <select class="form-control select-control">
                          <option>Lowest</option>
                          <option>highest</option>
                        </select></label>
                    </form> */}

                    <button
                    type = "button"
                    onClick = {() => {
                      // search_daata()
                      if (isMobile) {
                        setShow(true)
                      }
                    }}
                      class="btn btn-outline d-flex d-sm-none me-3 ms-3 search-mobile-btn"
                    >
                      <i class="icon icon-search"></i>
                    </button>
                  </div>

                  <div class="col-12"><hr class="mt-0 mb-4" /></div>
                  <div class="col-12 pro_list">
                   



                    <div
                        class="row"
                        id="scrollableDiv"
                        style={{
                          height: window.innerHeight + document.documentElement.scrollTop,
                          overflow: 'auto',
                        }}
                      >
                      <InfiniteScroll
                        dataLength={products.length}
                        next={() => fetchMoreData(page)}
                        // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                        // inverse={false} 
                        hasMore={has_more}
                        className= "row"
                        loader={
                          <div class="col-12 text-center infinite-scroll-col">
                            <div class="infinite-scroll-display">
                              <div class="infinite-scroll-display__loading-more">
                                <span class="loading-icon">
                                  <svg
                                    class="spinner"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 66 66"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle 
                                      class="path"
                                      fill="none"
                                      stroke-width="6"
                                      stroke-linecap="round"
                                      cx="33" 
                                      cy="33"
                                      r="30"
                                    ></circle>
                                  </svg>
                                </span>
                                <p>{'Loading more products'}</p>
                              </div>
                            </div>
                          </div>
                        }
                        endMessage={
                          <div class="col-12 text-center infinite-scroll-col">
                            <div class="infinite-scroll-display">
                              <div class="infinite-scroll-display__loading-end">
                                <p>You have reached the end of this category</p>
                              </div>
                            </div>
                          </div>
                        }
                        scrollableTarget="scrollableDiv"
                      >
                        {products.map((ele, i) => (
                          <Product 
                            action = {props.action}
                            products = {props.products}
                            key = {i} data = {ele}/>
                        ))}
                      </InfiniteScroll>
                    </div>
                      

                   
                    {/* <div class="row">

                  

                     {

                      !products || props.loading ?

                      [1,2,3,4].map((ele, i) => {
                        return (
                          <div key = {i} class="col-xl-3 col-lg-3 col-md-4 col-sm-6 prod">
                            <Loader type = 'product'/>
                          </div>
                        )
                      })

                      :

                      products && products.length > 0

                      ?

                      products.map((ele, i) => {
                        return (
                          <Product 
                          action = {props.action}
                          products = {props.products}
                          key = {i} data = {ele}/>
                        )
                      })

                      :
                      <div class="col-12 text-center infinite-scroll-col">
                        <p>{'No product found'}</p>
                      </div>
                   
                     }

                    </div> */}
                  </div>
                </div>

                {/* <div class="col-12 text-center infinite-scroll-col">
                  <div class="infinite-scroll-display">
                    <div class="infinite-scroll-display__loading-more">
                      <span class="loading-icon">
                        <svg
                          class="spinner"
                          width="24px"
                          height="24px"
                          viewBox="0 0 66 66"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            class="path"
                            fill="none"
                            stroke-width="6"
                            stroke-linecap="round"
                            cx="33"
                            cy="33"
                            r="30"
                          ></circle>
                        </svg>
                      </span>
                      <p>{props.loading?'Loading more products' : ''}</p>
                    </div>
                    {/* <div class="infinite-scroll-display__loading-end">
                      <p>You have reached the end of this category</p>
                    </div> 
                  </div>
                </div> */}
              </div>
            </div>


    
 
    </>
  )
}

