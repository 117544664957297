

export function _calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function toFeet(n) {
  var realFeet = ((n*0.393700) / 12);
  var feet = Math.floor(realFeet);
  var inches = Math.round((realFeet - feet) * 12);
  return feet + "'" + inches + '\"';
}

export function validateEmail(text){
    if (!text) {
        return false;
    }
    if (text.length < 3) {
        return false;
    }
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
    if(reg.test(text) === false)
    {
        return false;
    } else {
        return true;
    }
}

export function validatePassword(text){
    if (!text) {
        return false;
    }
    // let reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    // let reg = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/;
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.?@(+_)~#$%\^&\*])(?=.{8,})/;
    if(reg.test(text) === false)
    {
        return false;
    } else {
        return true;
    }

    // return /[A-Z]/.test(text) && /[0-9]/.test(text) && !/[aeiou]/.test(text) && /^[@#][A-Za-z0-9]{7,13}$/.test(text);

}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


export function getAmount(name, g) {
    // console.log(g);
    var amount = 0;
  
    // if (!g) {
    //   g = guests_no
    // }
  
    // if (name === 'cognac') {
    //   amount = g/10 * 2;
    // } else if (name === 'champagne') {
    //   amount = g/10 * 4;
    // } else if (name === 'whiskey') {
    //   amount = g/10 * 2;
    // } else if (name === 'wine') {
    //   amount = g/10 * 4;
    // } else if (name === 'sparkling wines') {
    //   amount = g/10 * 4;
    // } else if (name === 'beer') {
    //   amount = Math.round(g * 0.35);
    // } else if (name === 'water') {
    //   amount = g/10 * 2;
    // } else if (name === 'juice') {
    //   amount = g/10 * 4;
    // } else if (name === 'soft drinks') {
    //   amount = g/10 * 5;
    // } else if (name === 'energy drink') {
    //   amount = g/10 * 6;
    // } else if (name === 'vodka') {
    //   amount =  g/10;
    // } else if (name === 'tequila') {
    //   amount =  g/10;
    // } else if (name === 'gin') {
    //   amount =  g/10;
    // } 

    if (name === 'cognac') {
      amount = g/10 * 2;
    } else if (name === 'champagne') {
      amount = g/10 * 4;
    } else if (name === 'whisky') {
      amount = g/10 * 2;
    } else if (name === 'wines') {
      amount = g/10 * 4;
    } else if (name === 'sparkling wines') {
      amount = g/10 * 4;
    } else if (name === 'beer & ciders') {
      amount = Math.round(g * 0.35);
    } else if (name === 'water') {
      amount = g * 2;
    } else if (name === 'fruit juice & yoghurt') {
      amount = g/10 * 4;
    } else if (name === 'soft drinks') {
      amount = g/10 * 5; 
    } else if (name === 'energy & sport drinks') {
      amount = g/10 * 6;
    } else if (name === 'vodka') {
      amount =  g/10;
    } else if (name === 'tequila') {
      amount =  g/10;
    } else if (name === 'gin') {
      amount =  g/10;
    } 
  
    return amount;
  
}


export function reformat_name(name) {

  // if (name === 'cognac') {
  //   return 'cognac';
  // } else if (name === 'champagne') {
  //   return 'champagne';
  // } else if (name === 'whiskey') {
  //   return 'whisky';
  // } else if (name === 'wine') {
  //   return 'wines';
  // } else if (name === 'sparkling wines') {
  //   return 'sparkling wines';
  // } else if (name === 'beer') {
  //   return 'beer & ciders';
  // } else if (name === 'water') {
  //   return 'water';
  // } else if (name === 'juice') {
  //   return 'juice';
  // } else if (name === 'soft drinks') {
  //   return 'soft drinks';
  // } else if (name === 'energy drink') {
  //   return 'energy & sport drinks';
  // } else if (name === 'vodka') {
  //   return 'vodka';
  // } else if (name === 'tequila') {
  //   return 'tequila';
  // } else if (name === 'gin') {
  //   return 'gin';
  // }


  return name;
}
  

export function getFormattedDate(oldDate) {
    
    var tempDate = new Date(oldDate);
    var oldTime = Math.round(tempDate.getTime() / 1000);
    var shownDate = '';
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                      
    var year = tempDate.getFullYear(); 
    var month   = tempDate.getMonth();
    var day     = tempDate.getDate();                 
    shownDate = months[month] + ' ' + day + ', ' + year;    
             
    return shownDate;
 }

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return mDisplay + sDisplay; 
}

export function validateBVN(text) {
    if(text.length != 11) { 
        return false;
    } else {
        return true
    } 
}

export function randomString(length = 10, chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ") {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function showCurrency(type) {
    if (type == "naira") {
        return "₦";
    } else if (type == "dollar") {
        return "$";
    } else if (type == "pound") {
        return "£";
    } else if (type == "euro") {
        return "€";
    } else {
        return "₦";
    }
}


export function showC(name) {
    if (name == "NGN") {
        return "naira";
    } else if (name == "USD") {
        return "dollar";
    } else if (name == "EUR") {
        return "euro";
    } else if (name == "GBP") {
        return "pound";
    } else {
        return "naira";
    }
}


export function showC2(name) {
    if (name == "naira") {
        return "NGN";
    } else if (name == "dollar") {
        return "USD";
    } else if (name == "euro") {
        return "EUR";
    } else if (name == "pound") {
        return "GBP";
    } else {
        return "NGN";
    }
}


export function number_format (number, decimals, dec_point, thousands_sep) {
  

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN'
      });
      
      return formatter.format(number); 
  }
  


  export function new_number_format(num) {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }


  export function is_it_there(name) {
    return obj => obj.category === name;
  }


export function roundNumber(num, scale = 2) {
    if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
        sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}

