import React, {useState} from "react"

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Header from "../../../components/header";
import Footer from "../../../components/footer";
import Buy from "./buy";
import Proposal from "./proposal";
import Email from "./email";

export default function Invoice(props) {

  const [index, setIndex] = useState(0);


  const { selected, user, products, tel, email, listLoading, actionsLoading } = useSelector(
    (state) => ({ 
      selected: !Array.isArray(state.app.selected) ? JSON.parse(state.app.selected) : [],
      products: !Array.isArray(state.app.products) ? JSON.parse(state.app.products) : [],
      listLoading: state.app.listLoading,
      email: state.app.email,
      user: state.auth.user,
      tel: state.app.tel,
      actionsLoading: state.app.actionsLoading
    }),
    shallowEqual
  );


  return (
      <React.Fragment>
          <main class="--page-drinksparty-invoice">
          <section class="page-title bg-primary-light">
            <div class="container">
              <div class="row">
                <div class="col-lg-10 m-auto">
                  <ul
                    class="nav justify-content-center nav-tabs"
                    id="invoiceTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="buy-invoice-tab"
                        onClick={() => setIndex(0)}
                        data-bs-toggle="tab"
                        data-bs-target="#email"
                        type="button"
                        role="tab"
                        aria-controls="buy"
                        aria-selected="true"
                      >
                        <span class="d-none d-sm-block">Buy Products</span>
                        <span class="d-block d-sm-none">Buy Products</span>
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="email-invoice-tab"
                        data-bs-toggle="tab"
                        onClick={() => setIndex(1)}
                        data-bs-target="#email"
                        type="button"
                        role="tab"
                        aria-controls="email"
                        aria-selected="true"
                      >
                        <span class="d-none d-sm-block">Send to my email</span>
                        <span class="d-block d-sm-none">Email me</span>
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="proposal-invoice-tab"
                        data-bs-toggle="tab"
                        onClick={() => setIndex(2)}
                        data-bs-target="#proposal"
                        type="button"
                        role="tab"
                        aria-controls="proposal"
                        aria-selected="false"
                      >
                        <span class="d-none d-sm-block">Send as a proposal</span>
                        <span class="d-block d-sm-none">Send proposal</span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-7 m-auto">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active text-center"
                      id="buy"
                      role="tabpanel"
                      aria-labelledby="email-invoice-tab"
                    >
                      <p class="md">
                        Determining the amount alcohol needed for an event is tricky
                        because there is no perfect answer. Drinks.ng Event
                        Calculator will make it much easier than simply guessing.
                      </p>
                    </div>
                    <div
                      class="tab-pane fade text-center"
                      id="email"
                      role="tabpanel"
                      aria-labelledby="email-invoice-tab"
                    >
                      <p class="md">
                        Determining the amount alcohol needed for an event is tricky
                        because there is no perfect answer. Drinks.ng Event
                        Calculator will make it much easier than simply guessing.
                      </p>
                    </div>
                    <div
                      class="tab-pane fade text-center"
                      id="proposal"
                      role="tabpanel"
                      aria-labelledby="proposal-invoice-tab"
                    >
                      <p class="md">
                        Determining the amount alcohol needed for an event is tricky
                        because there is no perfect answer. Drinks.ng Event
                        Calculator will make it much easier than simply guessing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {
            index === 0
            ?
            <Buy user = {user} history = {props.history} cats = {selected} products = {products}/>
            :
            index === 1
            ?
            <Email user = {user} history = {props.history} cats = {selected} products = {products}/>
            :
            <Proposal user = {user} history = {props.history} tel = {tel} email = {email} cats = {selected} products = {products}/>
          }

          <Footer/>
        </main>
        
      </React.Fragment>
    )
}