import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

// import * as auth from "../pages/Auth/_redux/authRedux";
import * as auth from "../pages/_redux/auth/authRedux";
import { appSlice } from "../pages/_redux/app/Slice";
// import { AlertSlice } from "../app/modules/Incidents/_redux/incidents/Slice";
// import {AccountSlice} from "../app/modules/Agents/_redux/accounts/Slice";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import config from "../config";

const persistConfig = {
  key: config.KEY,
  storage
};

const persistedReducer = persistReducer(persistConfig, appSlice.reducer);


export const rootReducer = combineReducers({
  auth: auth.reducer,
  app: persistedReducer //appSlice.reducer
});

// export function* rootSaga() {
//   yield all([auth.saga()]);
// }
