import React from "react"


export default function Footer() {

    return (
     


        <footer className="calculator-footer">
            <div className="container">
            <div className="row">
                <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0">

                <div className="--footer-content">
                <div className="row">
                    <div className="col-12 --top-content">
                    <p><small>Please remember that the estimates provided are not exact. Based on experience with all types of
                        events, this is what tends to be needed but it's not an exact science</small></p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 text-start">
                    <p><small>© copyright {new Date().getFullYear()} drinks.ng all rights reserved</small></p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-5 text-end">
                    <p><a href="" target="_BLANK"><small>Terms & condition</small></a></p>
                    </div>

                    </div>

                </div>
                </div>
            </div>
            </div>
        </footer>


    )
}