/**
 * Entry application component used to compose providers and render Routes.
 * */

 import React, { useEffect } from "react";
 import { Provider } from "react-redux";
 import { BrowserRouter as Router } from "react-router-dom";
 import { PersistGate } from "redux-persist/integration/react";
 import { RoutesStack } from "./pages/Routes";
// import Home from "./pages/App/home" 
import NProgress from "nprogress";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LazyLoad = () => {
  useEffect(() => {
      NProgress.start();

      return () => {
          NProgress.stop();
      };
  });

  return '';
};


 export default function App({ store, persistor, basename }) {
   return (
     /* Provide Redux store */
     <Provider store={store}>
       <PersistGate persistor={persistor} loading={<div/>}>
         <React.Suspense fallback={<LazyLoad/>}>
           <Router>
              <RoutesStack />
              <ToastContainer 
                  progressClassName="toastProgress"
                  bodyClassName="toastBody"
              />
           </Router>
         </React.Suspense>
       </PersistGate>
     </Provider>
   );
 }
 