export default {
    // "URL": "https://drinks-ng-marketplace.nw.r.appspot.com/api/", 
    "URL": "https://prod-dot-drinks-ng-marketplace.nw.r.appspot.com/api/",
 
    "categories": ['cognac', 'champagne', 'whisky', 'wines', 
    'sparkling wines', 'beer & ciders', 'water', 'fruit juice & yoghurt', 'soft drinks', 'energy & sport drinks', 'vodka', 'tequila', 'gin'], 

    "threshold_categories": ['champagne', 'whisky', 'cognac', 'tequila'],
    "threshold_amount": 500000,

    "KEY": "STUPID FOOL"
}


// • Cognac - 2 Per 10 People
// Champagne - 4 Per 10 People
// Whisky - 2 Per 10 People
// Wines - 4 Per 10 People
// Sparkling Wine - 4 Per 10 People
// Beer - 35 Percent of the crowd
// • Water - 2 Per 10 People
// Juice - 4 Per 10 People
// • Soft drinks - 5 Per 10 People
// •
// Energy drink - 6 Per Every Bottle of Spirit
// • Vodka - 1 Per 10 People
// ..Tequila_-1 Per 10 People
// • Gin - 1 Per 10 People