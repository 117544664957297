import React from "react";
import * as functions from "../../global/functions"

export default function Product(props) {
    const data = props.data;
    const prod = props.data.product;
    // console.log("Data", data);
    // console.log("Prod", prod);

    // return;
    var amount = 0;


    let obj = props.products.find(x => x.id === data.id);
    let index = props.products.indexOf(obj);

    if (obj) {
        amount = props.products[index].amount;
    }

    // console.log(data)
    // console.log(props.products)

    // return null;

    return (
        <div class={`col-xl-3 col-lg-3 col-md-4 col-sm-6 prod ${amount > 0 ? 'active' : ''}`}>
            <div class="prod_main-cont">
                <div class="product-qty-count top">
                <label
                    ><input
                    type="hidden"
                    class="--category"
                    value="500"
                    id="cat-10"
                /></label>
                <span class="--btl-count" id="val-1"
                    ><span class="--value">{amount}</span><small>BTL</small></span>
                <span class="--action">
                    <a 
                    onClick={() => props.action ? props.action(data.id, 'rem', JSON.stringify(data)) : console.log('')} 
                    href = "javascript:;"
                    class="--minus" data-ref="1"
                    ><i class="icon icon-minus"></i></a>
                    <a
                    onClick={() => props.action ? props.action(data.id, 'add', JSON.stringify(data)) : console.log('')} 
                    href = "javascript:;"
                    class="--plus" data-ref="1"><i class="icon icon-add"></i></a>
                </span>
                </div>
                <div class="prod_img-cont text-center">
                <a href="javascript:;">
                    <img
                    src={prod.image}
                    class="img-fluid"
                    alt=""
                    />
                </a>
                </div>
                <div class="cont">
                <h3 class="prod_title">
                    <a href="javascript:;"
                    >{prod.name}</a>

              
                </h3>

                <h4 class="prod_title" style={{
                    fontSize:13, marginTop: -5
                }}>
                   

                <a href="javascript:;"
                    >{data.store}</a>
                </h4>

                <div class="prod_meta-data">
                    <span class="prod_rating"
                    >
                        <i class="icon icon-star-solid"></i>
                    <span class="prod_rating-value"
                        >{data.average_rating ? data.average_rating : '0'}</span></span>
                    <span class="prod_btl-size"
                    ><span class="prod_btl-size-value"
                        >{data.attribute ? data.attribute.name : 'Nill'} <small>BTL</small></span></span>
                    <span class="prod_total-estimate text-end"
                    ><span class="prod_total-estimate-value"
                        >3 CTN, {data.attribute2 ? data.attribute2.name : ''}
                    </span></span>
                </div>
                </div>

                <div class="extra-cont cont">
                <hr />
                <span class="prod_unit-price prod_price">
                    <small class="prod_price_label overline"
                    >Unit Price</small
                    >{functions.number_format(data.selling_price.amount )}
                </span>
                <span class="prod_total-price prod_price">
                    <small class="prod_price_label overline"
                    >Total Price</small
                    >{functions.number_format(data.selling_price.amount * amount )}
                </span>

                <div class="d-grid gap-2">
                    <a
                    href="javascript:;"
                    class="btn btn-outline btn-block btn-md"
                    onClick={() => props.action ? props.action(data.id, 'del', JSON.stringify(data)) : console.log('')} 
                    >
                    Remove Product</a>

                    {
                        data.out_of_stock

                        ?

                        <a
                        href="javascript:;"
                        aria-disabled
                        onClick={() => console.log('')} 
                        class="btn btn-block btn-md"
                        >out of stock!</a>

                        : 

                        <a
                        href="javascript:;"
                        onClick={() => props.action ? props.action(data.id, 'add', JSON.stringify(data)) : console.log('')} 
                        class="btn btn-primary btn-block btn-md"
                        >Add Product</a>
                    }
                    
                </div>
                </div>
            </div>
        </div>
    )
    
}