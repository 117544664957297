import React, { useEffect } from "react"


export default function Category_List(props) {

    var amount = 0;

    // useEffect(() => {
    //     console.log('change detected!', props.current_amount)
    // }, [props])

    let obj = props.selected.find(x => x.name === props.name);
    let index = props.selected.indexOf(obj);

    if (obj) {
        amount = props.selected[index].amount;
    }
    // console.log(amount);
    
    // const curr = props.current_d;
    // var amount = props.selected.find(o => o.name === props.name).amount;
    // console.log(props);
    return (
        <li style= {{
            margin: '10px 0'
          }}>
            <label><input type="hidden" className="--category" value="90" id="qty-1" /></label>
            <label className={`--category-radio-cont ${amount > 0 ? 'active' : ''}`}>
              <input type="checkbox" checked />
              <span className="--category-cont">

                <span 
                // onClick={() => props.action ? props.action(props.name, 'add') : console.log('')}
                 className="--active">
                  <div className="product-qty-count top">
                    <span className="--btl-count" id="val-1"><span className="--value">{amount}</span><small>BTL</small></span>
                    <span className="--action">
                      <span className="--minus" onClick={() => props.action ? props.action(props.name, 'rem') : console.log('')} data-ref="1"><i className="icon icon-minus"></i></span>
                      <span className="--plus" onClick={() => props.action ? props.action(props.name, 'add') : console.log('')}  data-ref="1"><i className="icon icon-add"></i></span>
                    </span>
                  </div>
                  <span className="bottom">
                    {/* <span className="--qty">10 CTN, 2 BTL </span> */}
                    <span className="--qty">{amount} BTL </span>
                    <span className="--label">{props.name.toUpperCase()}</span>
                    {/* <span className="--price">₦1,000,000.00</span> */}
                  </span>
                  <span className="--remove"
                    onClick={() => props.action ? props.action(props.name, 'del') : console.log('')}
                  ><i className="icon icon-close"></i></span>
                </span>


                <span onClick={() =>  props.calibrate ? props.calibrate(props.name) : console.log('')} className="--inactive">
                  <span className="--icon" 
                  // onClick={() => props.calibrate ? props.calibrate(props.name) : console.log('')}
                   ><i className="icon icon-add"></i></span>
                  <span className="bottom">
                    <span className="--label">{props.name.toUpperCase()}</span>
                    <small>Add This Category</small>
                  </span>
                </span>

              </span>
            </label>
          </li>

    )
}