import React, { useState, useEffect } from "react"


import Loader from "../../../components/loader";
import Product from "../../../components/product";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/app/Actions";
import * as functions from "../../../global/functions";
import config from "../../../config";
import { toast } from 'react-toastify';

import { withRouter } from 'react-router'



export default function Buy(props) {
  const dispatch = useDispatch();



  const { global_cart, actionLoad,  user } = useSelector(
    (state) => ({ 
      global_cart: state.app.global_cart,
      user: state.auth.user,
      actionLoad: state.app.actionsLoading
    }),
    shallowEqual
  );


  useEffect(() => {
    dispatch(actions.fetchAllData('order', `?user=${user.uuid}`))
  }, [])


  function save() {
    toast.info(`Success: Order saved`);
   }


  function getPrice(c) {
    var p = 0;
    props.products.forEach((ele) => {
      if (ele.category === c) {
        p+=Number(ele.product.selling_price.amount * ele.amount);
      }
    })

    return p;
   }


   function getAllPrice() {
    var p = 0;
    props.products.forEach((ele) => {
      p+=Number(ele.product.selling_price.amount * ele.amount);
    })

    return p;
   }


   function submit() {
    if (global_cart.length > 0) {

      sendData()
    } else {
      var items = [];
  
      props.products.forEach((ele, i) => {
        var d = {};
        d['qty'] = ele.amount;
        dispatch(actions.addData(d, ele.product.uuid)).then((res) => {
        
          if (res.data.item_uuid) {
            items.push(res.data.item_uuid);
          }
  
          if (i === (props.products.length - 1)) {
            //redirect to drinks website
  
            dispatch(actions.write_data("stock", items));
            // window.open("https://drinks.ng/basket", '_blank');
            setTimeout(() => {
                window.open("https://drinks.ng/basket", '_blank');
            })
          }
          
        })
      })
    }
    


   
    
   }



  //  useEffect(() => {
  //   alert("it is me!");
  //  }, [])


   async function sendData () {

    // reform data;

    var products = props.products;
   
    const data = global_cart;
  

    await Promise.all(data.map(async (sss) => {
      const contents = await dispatch(actions.addData({}, sss.uuid, "remove"));
    }));

    // return;


    await Promise.all(products.map(async (ele) => {
      var d = {};
      d['qty'] = ele.amount;
      const contents2 = await dispatch(actions.addData(d, ele.product.uuid));
      toast.info(`${ele.product.product.name} - ${contents2.data.message}`);
    }));


    dispatch(actions.fetchAllData('order', `?user=${user.uuid}`))


    setTimeout(async () => {
      window.open("https://drinks.ng/basket", '_blank');
    })


   
 
  }

  return (
    <>
   
   <section class="--page-content">
        <div class="container">
          <form class="row">
            <div class="col-xl-10 col-lg-12 mt-3 m-auto">
              <div class="drinksparty-invoice__section-title">
                <p class="title">Product Information</p>
                <p class="invoice-code md">
                  {/* <span>Invoice no.</span><span>24578908</span> */}
                </p>
              </div>
            </div>

            <div class="col-xl-10 col-lg-12 m-auto">
              <div class="drinksparty-invoice__party-cart-list mini-cart">
                <div class="mini-cart-list large">
                  <ul class="mini-cart-list__container p-0">
                  {
                  props.cats.map((ele, i) => {
                    if (props.products.some(functions.is_it_there(ele.name))) {
                      return (
                        <li key = {i} class="mini-cart-list__category">
                            <div class="mini-cart-list__main-title">
                              <p class="title">{functions.capitalizeFirstLetter(ele.name)}</p>
                            </div>
                            <div class="mini-cart-list__content">
                              <ul>
                                {
                                  props.products.map((d, ind) => {
                                    if (d.category === ele.name) {
                                      return (
                                        <li key = {ind}>
                                          <div class="mini-cart-list__img-cont">
                                            <img
                                              src={d.product.product.image}
                                              alt=""
                                              class="img-fluid"
                                            />
                                          </div>
                                          <div class="mini-cart-list__prod-info">
                                            <p class="mini-cart-list__title md">
                                              {functions.capitalizeFirstLetter(d.product.product.name)}
                                            </p>
                                            <small class="mini-cart-list__qty"
                                              >{d.product.attribute ? d.product.attribute.name : 'Product '}
                                              {d.product.attribute2 ? d.product.attribute2.name : ''}
                                            </small>
                                          </div>
                                          <div class="mini-cart-list__price-info text-end">
                                            <div class="mini-cart-list__price cat-unit-price">
                                              <p class="mini-cart-list__value md">
                                              {functions.number_format(d.product.selling_price.amount)}
                                              </p>
                                              <small class="mini-cart-list__label"
                                                >Single Price</small
                                              >
                                            </div>

                                            <div
                                              class="mini-cart-list__price cat-total-price"
                                            >
                                              <p class="mini-cart-list__value md">
                                              {functions.number_format(d.product.selling_price.amount * d.amount)}
                                              </p>
                                              <small class="mini-cart-list__label"
                                                >Unit Total</small>
                                            </div>
                                          </div>

                                          {/* <a href="" class="mini-cart-list__delete-btn"
                                            ><i class="icon icon-delete"></i
                                          ></a> */}
                                        </li>
                                      )
                                    }
                                  })
                                }

                             
                              </ul>
                            </div>
                            <div class="mini-cart-list__extra-info">
                              <p class="mini-cart-list__clear md">
                                {/* <a href="">Clear All</a> */}
                              </p>
                              <p class="title mini-cart-list__sub-total md">
                                <span>Sub Total</span><span>{functions.number_format(getPrice(ele.name))}</span>
                              </p>
                            </div>
                          </li>
                      )
                    }
                      
                  })
                }
                     
                    

               
                  </ul>

                  <div class="mini-cart-main-total p-0">
                    <hr class="lg" />
                    <div class="mini-cart-main-total__item">
                      <p class="mini-cart-main-total__label md">
                        Estimate for Drinks
                      </p>
                      <p class="mini-cart-main-total__value md">{functions.number_format(getAllPrice())}</p>
                    </div>
                    <div class="mini-cart-main-total__item">
                      <p class="mini-cart-main-total__label md">
                        Estimate for Services
                      </p>
                      <p class="mini-cart-main-total__value md">{functions.number_format(0)}</p>
                    </div>
                    <div class="mini-cart-main-total__item">
                      <p class="mini-cart-main-total__label md">Grand Total</p>
                      <h3
                        class="mini-cart-main-total__value title mini-cart-main-total__grand-total"
                      >
                        {functions.number_format(getAllPrice())}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-10 col-lg-12 m-auto mt-2">
              <hr />

              <div class="drinksparty-invoice__btn-container mt-5 row">
                <div class="col-lg-6 drinksparty-invoice__btn-holder-save">
                  <div class="btn-holder">
                  <a href = {'javascript:;'} onClick={() => save()} class="btn btn-outline btn-md">
                      <i class="icon icon-heart_outline"></i>Save Order
                    </a>
                  </div>
                </div>
                <div class="col-lg-6 text-end">
                  <div class="btn-holder">
                    <a href = {'javascript:;'} onClick={() => props.history.goBack()} class="btn btn-outline btn-md">
                      Back to Shopping
                    </a>
                    <a href="javascript:;" disabled = {actionLoad} onClick={() => submit()}  class="btn btn-success btn-md">
                      {actionLoad ? 'Please wait...' : 'Proceed To Checkout'}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

