import React, { useState, useEffect, useRef } from "react"

import Header from "../../../components/header";
import Footer from "../../../components/footer";
import TabData from "./view";
import {RemoveScrollBar} from 'react-remove-scroll-bar';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/app/Actions";
import * as functions from "../../../global/functions";
import config from "../../../config";


import $ from 'jquery';
import 'jquery-ui-bundle';

export default function Drinks(props) {
  const dispatch = useDispatch();
  const myRef = useRef(null);

  const [unselected, setUnSelected] = useState([]);

  const [warning_shown, setWarning] = useState(false);

  const { user, global_cart, categories, sel, selected_str, budget, guests_no, listLoading, actionsLoading } = useSelector(
    (state) => ({ 
      selected_str: !Array.isArray(state.app.selected) ? JSON.parse(state.app.selected) : [],
      budget: state.app.budget,
      user: state.auth.user,
      categories: state.app.categories,
      global_cart: state.app.global_cart,
      sel: state.app.products,
      guests_no: state.app.guests_no,
      listLoading: state.app.listLoading,
      actionsLoading: state.app.actionsLoading
    }),
    shallowEqual
  );

  const [selected, setSelected] = useState(selected_str);

  useEffect(() => {
    dispatch(actions.fetchAllData('order', `?user=${user.uuid}`))
  }, [])

  const [products, setProducts] = useState(!Array.isArray(sel) ? JSON.parse(sel) : []);



  const [selected_tab, setTab] = useState(selected && selected.length > 0 ? selected[0] : null);


  useEffect(() => {
    // console.log('change registrered')
    $(".select-control").selectmenu();

  
    dispatch(actions.write_data('products', JSON.stringify(products)))

    var bud = Number(budget);
    // console.log(getAllPrice(), bud)
    if ((getAllPrice() > bud) && !warning_shown) {
      // alert('ha')
      myRef.current.click();
      setWarning(true);
    }

 
  }, [products])




  useEffect(() => {
    updateUnsel()
    dispatch(actions.write_data('selected', JSON.stringify(selected)))


    if (selected.length === 0) {
      props.history.push('/');
      return;
    } 
    
  }, [selected])


  function updateUnsel() {
    var unsel = [];

    config.categories.forEach((ele, i) => {
      var ind = selected.findIndex(i => i.name.toLowerCase() === ele.toLowerCase());
      if (ind === -1) {
        var obj = {};
        obj['amount'] = 0;
        obj['name'] = ele;
        unsel.push(obj);
      }
      
    })

    setUnSelected([...unsel])
  }






 function cat_action(name, action) {
  var arr = selected;
  let obj = arr.find(x => x.name === name);
  let index = arr.indexOf(obj);


  
  if (index !== -1) {
    arr.splice(index, 1);
    setSelected([...arr]);
    return;
  } else {
    var objd = {};
    objd['amount'] = functions.getAmount(name, guests_no);
    objd['name'] = name;
    arr.push(objd)
  }

  setSelected([...arr])
  

 }


  


  function action(id, action, data) {
    var arr = products;
    let obj = arr.find(x => x.id === id);
    let index = arr.indexOf(obj);
  

    //if you add new, add, if you update, look for id in global and delete, the send new, if you delete, look for id and delete
  
    
    if (index !== -1) {
    
      
        if (action === 'del') {
          arr.splice(index, 1); 
          setProducts([...arr])
          return;
        }
  
        var am = arr[index].amount;
        if (am === 1) {
          if (action === 'rem') {
            arr.splice(index, 1); 
          } else {
            arr[index].amount++;
          }
        } else {
          if (action === 'rem') {
            arr[index].amount--;
          } else {
            arr[index].amount++;
          }
        }
    } else {
      if (action === 'add') {
  
        var objd = {};
        objd['amount'] = 1;
        if (data) {
          objd['product'] = JSON.parse(data);
        }
        objd['id'] = id;
        objd['category'] = selected_tab.name;
        arr.push(objd)
      }
    }
    

    setProducts([...arr])
    
  
   }


   function getPrice(c) {
    var p = 0;
    products.forEach((ele) => {
      if (ele.category === c) {
        p+=Number(ele.product.selling_price.amount * ele.amount);
      }
    })

    return p;
   }


   function getAllPrice() {
    var p = 0;
    products.forEach((ele) => {
      p+=Number(ele.product.selling_price.amount * ele.amount);
    })

    return p;
   }


   function getBottles(c) {
    var p = 0;
    products.forEach((ele) => {
      if (ele.category === c) {
        p+=Number(ele.amount);
      }
    })

    return p;
   }

   

  function clearAll(e) {
      products.forEach((p, i) => {
        if (p.category === e) {
          action(p.product.id, 'del', '')
        }
      })
   }


  return (
    <React.Fragment>
       <RemoveScrollBar/>
   <main class="--page-drinksparty" style={{ overflowY: 'auto' }}>
      <section class="page-title bg-primary">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:;" onClick = {() => props.history.push('/')}>Home</a></li>
                  <li class="breadcrumb-item"><a href="javascript:;" onClick = {() => props.history.goBack()}>Drinks Party</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Estimated Calculation
                  </li>
                </ol>
              </nav>
              <h2 class="title">Select your Products</h2>
            </div>
          </div>
        </div>
      </section>

      <section class="--page-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-lg-4 sidebar" id="sidebar">
              {/* <div class="mobile-side-bar d-none d-sm-grid d-md-grid d-lg-none">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Mobile Sidebar List"
                >
                  <button type="button" class="btn btn-primary">
                    <i class="icon"></i>
                  </button>
                  <button type="button" class="btn btn-primary">
                    Champagnes
                  </button>
                </div>
              </div> */}

              <div
                class="sidebar-cont bg-light filter--nav cal--list sticky-display"
              >
                <form>
                  <div class="">
                    <ul>


                    {selected.map((ele, i) => {
                        return (
                          <li key = {i} class={`filter--nav__item cat__active ${selected_tab && selected_tab.name === ele.name ? 'cat__current' : ''}`}>
                          <a href="javascript:;" 
                          onClick={() => cat_action(ele.name, 'del')}
                          class="filter--nav__canel"><i class="icon icon-close"></i></a>
                          <a href="javascript:;" onClick={() => setTab(ele)} class="filter--nav__link">
                            <span class="filter--nav__label">{ele.name.toUpperCase()}</span>
                            <span class="filter--nav__cat_qty">{ele.amount} <small>BTL</small></span>
                            {
                              getBottles(ele.name) === ele.amount &&
                              <span class="filter--nav__cat_visited_symb"><i class="icon icon-circle-check"></i></span>
                            }
                          </a>
                        </li>
                        )
                     })}


                    {unselected.map((ele, i) => {
                        return (
                        <li  key = {i} class="filter--nav__item cat__inactive">
                        <span class="filter--nav__label">{functions.capitalizeFirstLetter(ele.name)}</span>
                        <a href="javascript:;" 
                          onClick={() => cat_action(ele.name, 'add')}
                          class="btn btn-sm btn-outline">add category</a>
                        </li>
                        )
                     })}


                    

                    </ul>
                  </div>

                  <div class="filter--nav__item_bottom-info">
                    <div class="filter--nav__divider"><hr class="mt-0" /></div>
                    <div class="filter--nav__cost_cont">
                      <p class="filter--nav__cost-info">
                          <small class="filter--nav__cost-label"
                            >{warning_shown ? `New Budget` : `Current Budget`}</small>
                          <small class="filter--nav__cost-value"
                          >{warning_shown ? functions.number_format(getAllPrice()) : functions.number_format(budget)}</small>
                      </p>

                      <p class="filter--nav__cost-info">
                        <small class="filter--nav__cost-label"
                          >Total Estimate</small>
                        <small class="filter--nav__cost-value"
                          >{functions.number_format(getAllPrice())}</small>
                      </p>
                    </div>
                    <div class="filter--nav__divider"><hr /></div>

                    <div class="filter--nav__item_btn-cont">
                      <button type="button"
                      disabled = {selected.findIndex(x => x.name === selected_tab.name) === 0}
                      onClick={() => {
                        setTab(selected[selected.findIndex(x => x.name === selected_tab.name) - 1]);
                        return false;
                      }} class="btn btn-outline btn-sm">
                        Prev <span>Category</span><i></i>
                      </button>
                      <button 
                      disabled = {(selected.findIndex(x => x.name === selected_tab.name) + 1) >= selected.length}
                      type="button" onClick={() => {
                        setTab(selected[selected.findIndex(x => x.name === selected_tab.name) + 1]);
                        return false;
                      }} class="btn btn-outline btn-sm">
                        Next <span>Category</span><i></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {selected_tab ?
              <TabData selected = {selected_tab} 
              loading = {actionsLoading}
              action = {action}
              categories = {categories}
              products = {products}
              bottle_no = {selected_tab ? getBottles(selected_tab.name) : 0}
              cat_cost = {functions.number_format(selected_tab ? getPrice(selected_tab.name) : 0)}
              />
              : null
            }

          </div>
        </div>
      </section>


      <section class="fixed-bottom bg-light bottom__control party-est-filter">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="bottom__control-control-content">
              <h4 class="top--filter__title title">{functions.capitalizeFirstLetter(selected_tab ? selected_tab.name : '')}</h4>
              <span class="top--filter__cat--price">
                <small class="label">Category Total</small>
                <span class="price">{functions.number_format(selected_tab ? getPrice(selected_tab.name) : 0)}</span>
              </span>

              <span class="hr-divider horizontal"></span>

              <span class="top--filter__ctn-qty">
                <i class="icon icon-carton-of-drinks"></i>
                <span>
                  <small class="label">Cartons</small>
                  <span class="qty">100</span>
                </span>
              </span>

              <span class="top--filter__btl-qty">
                <i class="icon icon-wine"></i>
                <span>
                  <small class="label">BTL</small>
                  <span class="qty">{selected_tab ? getBottles(selected_tab.name) : 0}</span>
                </span>
              </span>

              <div class="controls-nav">
                <span class="cart static-cart-display">
                  <small class="cart__label">Cart</small>
                  <button
                    type="button"
                    class="cart__info"
                    data-bs-toggle="modal"
                    data-bs-target="#mincart-modal-large"
                  >
                    <i class="icon icon-basket"></i>
                    <span class="cart__info--notification">{products.length}</span>
                  </button>
                </span>

                <div class="cat-nav">

                  <button
                    class="btn btn-outline btn-sm"
                    type="button"
                    style = {{display: "none"}}
                    data-bs-toggle="modal"
                    ref={myRef}
                    data-bs-target="#budget-exceed-modal"
                  >
                    Next <span>Category</span><i></i>
                  </button>

                  <button type="button"
                    disabled = {selected.findIndex(x => x.name === selected_tab.name) === 0}
                    onClick={() => {
                      setTab(selected[selected.findIndex(x => x.name === selected_tab.name) - 1]);
                      return false;
                    }} class="btn btn-outline btn-sm">
                      Prev <span>Category</span><i></i>
                    </button>
                    <button 
                    disabled = {(selected.findIndex(x => x.name === selected_tab.name) + 1) >= selected.length}
                    type="button" onClick={() => {
                      setTab(selected[selected.findIndex(x => x.name === selected_tab.name) + 1]);
                      return false;
                    }} class="btn btn-outline btn-sm">
                      Next <span>Category</span><i></i>
                    </button>

                    { 
                    selected.findIndex(x => x.name === selected_tab.name) + 1 >= selected.length &&
                   
                    <button
                      class="btn btn-success btn-sm"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#more-event-service-modal"
                    >
                      Continue to Checkout
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

      <div class="floating-cart d-xl-none d-lg-none d-md-block">
        <span class="cart static-cart-display">
          <button
            type="button"
            class="cart__info lg"
            data-bs-toggle="modal"
            data-bs-target="#mincart-modal-large"
          >
            <i class="icon icon-basket"></i>
            <span class="cart__info--notification dark-bdr">{products.length}</span>
          </button>
        </span>
      </div>
    </main>

    <div
      class="modal fade right-full mini-cart-modal mini-cart"
      id="mincart-modal-large"
      tabindex="-1"
      aria-labelledby="mincart-modal-large"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title title" id="exampleModalLabel">Cart</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="icon icon-close"></i>
            </button>
            <hr />
          </div>
          <div class="modal-body">
            <div class="mini-cart-list large">
              <ul class="mini-cart-list__container">
                {
                  selected.map((ele, i) => {
                    if (products.some(functions.is_it_there(ele.name))) {
                      return (
                        <li key = {i} class="mini-cart-list__category">
                          <div class="mini-cart-list__main-title">
                            <p class="title">{functions.capitalizeFirstLetter(ele.name)}</p>
                          </div>
                          <div class="mini-cart-list__content">
                            <ul>
                              {
                                products.map((d, ind) => {
                                  if (d.category === ele.name) {
                                    return (
                                      <li key = {ind}>
                                      <div class="mini-cart-list__img-cont">
                                        <img
                                          src={d.product.product.image}
                                          alt=""
                                          class="img-fluid"
                                        />
                                      </div>
                                      <div class="mini-cart-list__prod-info">
                                        <p class="mini-cart-list__title md">
                                          {functions.capitalizeFirstLetter(d.product.product.name)}
                                        </p>
                                        <small class="mini-cart-list__qty">{d.product.attribute ? d.product.attribute.name : 'Product'}</small>
                                      </div>
                                      <div class="mini-cart-list__price-info text-end">
                                        <div class="mini-cart-list__price cat-unit-price">
                                          <p class="mini-cart-list__value md">{functions.number_format(d.product.selling_price.amount)}</p>
                                          <small class="mini-cart-list__label"
                                            >Single Price</small
                                          >
                                        </div>
  
                                        <div class="mini-cart-list__price cat-total-price">
                                          <p class="mini-cart-list__value md">{functions.number_format(d.product.selling_price.amount * ele.amount)}</p>
                                          <small class="mini-cart-list__label"
                                            >Unit Total</small
                                          >
                                        </div>
                                      </div>
  
                                      <a href="javascript:;" 
                                      onClick = {() => action(d.product.id, 'rem', JSON.stringify(d))}
                                      class="mini-cart-list__delete-btn"
                                        ><i class="icon icon-delete"></i
                                      ></a>
                                    </li>
  
                                  
                                    )
                                  }
                                })
                              }
                            </ul>
                          </div>
                          <div class="mini-cart-list__extra-info">
                            <p class="mini-cart-list__clear md">
                              <a href="javascript:;" onClick={() => clearAll(ele.name)}>Clear All</a>
                            </p>
                            <p class="title mini-cart-list__sub-total md">
                              <span>Sub Total</span><span>{functions.number_format(getPrice(ele.name))}</span>
                            </p>
                          </div>
                        </li>
                      )
                    }
                    
                  })
                }

               
              </ul>

              <div class="mini-cart-main-total">
                <hr />
                <div class="mini-cart-main-total__item">
                  <p class="mini-cart-main-total__label md">
                    Estimate for Drinks
                  </p>
                  <p class="mini-cart-main-total__value md">{functions.number_format(getAllPrice())}</p>
                </div>
                <div class="mini-cart-main-total__item">
                  <p class="mini-cart-main-total__label md">
                    Estimate for Services
                  </p>
                  <p class="mini-cart-main-total__value md">0</p>
                </div>
                <div class="mini-cart-main-total__item">
                  <p class="mini-cart-main-total__label md">Grand Total</p>
                  <h4
                    class="mini-cart-main-total__value title mini-cart-main-total__grand-total"
                  >
                    {functions.number_format(getAllPrice())}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade budget-exceed-modal"
      id="budget-exceed-modal"
      tabindex="-1"
      aria-labelledby="budget-exceed-modal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="budget-exceed-modal__top-content">
              <svg
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.59961 3.6001H57.7662V41.4972L56.9545 40.1499C55.2341 37.2943 51.0936 37.2943 49.3733 40.1499L35.1342 63.7852H10.4433C6.66361 63.7852 3.59961 60.7212 3.59961 56.9415V3.6001ZM54.6801 41.5201L57.7662 46.6426V46.643L54.6799 41.5201C54.3798 41.0219 53.8954 40.741 53.3876 40.6774C53.8955 40.7409 54.3799 41.0219 54.6801 41.5201ZM49.5302 24.1902L9.93472 24.1902L9.93472 21.1106L49.5302 21.1106V24.1902ZM15.6363 34.9321H24.664V31.8525H15.6363V34.9321ZM18.6463 46.9682V49.9785H21.726V46.9682H24.6641V43.8886H21.726V40.9507H18.6463V43.8886H15.6363V46.9682H18.6463ZM43.0848 37.4242L41.0071 35.3465L38.8788 37.4748L36.7012 35.2972L38.8295 33.1689L36.7013 31.0406L38.8789 28.863L41.0071 30.9912L43.0848 28.9136L45.2624 31.0913L43.1848 33.1689L45.2625 35.2466L43.0848 37.4242Z"
                  fill="#FF5229"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M54.68 41.5203C53.9919 40.378 52.3357 40.378 51.6475 41.5203L36.3846 66.8549C35.6738 68.0347 36.5235 69.5385 37.9008 69.5385H68.4267C69.804 69.5385 70.6537 68.0347 69.9429 66.8549L54.68 41.5203ZM54.8987 60.8286L55.3235 52.2965H51.5177L51.9248 60.8286H54.8987ZM55.1819 65.5549V62.1562H51.6593V65.5549H55.1819Z"
                  fill="#AD2B0B"
                />
              </svg>

              <h4 class="title">Notice</h4>
              <p class="md">
                We see you have changed the estimate; in order to help you get
                an approximate calculation we will need to adjust your budget to
                fit your request
              </p>
            </div>
            <div class="budget-exceed-modal__bottom-content">
              <div class="budget-exceed-modal__notice">
                <small
                  >No worries about your current estimate getting lost, to get
                  it back you can just re-input your budget and
                  re-calculate</small
                >
              </div>
              <div class="d-grid">
                <button
                  type="button"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Adjust budget & Proceed
                </button>
                <button type="button" class="btn">No, thanks</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade left-full filter-modal"
      id="filter-modal-large"
      tabindex="-1"
      aria-labelledby="filter-modal-large"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title title" id="exampleModalLabel">Category</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <hr />
          </div>
          <div class="modal-body">
            <div class="sidebar modal-sidebar" id="sidebar">
              <div
                class="sidebar-cont bg-light filter--nav cal--list sticky-display"
              >
                <form>
                  <div class="">
                    <ul>
                    {selected.map((ele, i) => {
                        return (
                          <li key = {i} class={`filter--nav__item cat__active ${selected_tab && selected_tab.name === ele.name ? 'cat__current' : ''}`}>
                          <a href="javascript:;" 
                          class="filter--nav__canel"><i class="icon icon-close"></i></a>
                          <a href="javascript:;" onClick={() => setTab(ele)} class="filter--nav__link">
                            <span class="filter--nav__label">{ele.name.toUpperCase()}</span>
                            <span class="filter--nav__cat_qty">{ele.amount} <small>BTL</small></span>
                            {
                              getBottles(ele.name) === ele.amount &&
                              <span class="filter--nav__cat_visited_symb"><i class="icon icon-circle-check"></i></span>
                            }
                          </a>
                        </li>
                        )
                     })}

                    {unselected.map((ele, i) => {
                        return (
                        <li  key = {i} class="filter--nav__item cat__inactive">
                        <span class="filter--nav__label">{functions.capitalizeFirstLetter(ele.name)}</span>
                        <a href="javascript:;" 
                          onClick={() => cat_action(ele.name, 'add')}
                          class="btn btn-sm btn-outline">add category</a>
                        </li>
                        )
                     })}


                    

                    </ul>
                  </div>

                  <div class="filter--nav__item_bottom-info">
                    <div class="filter--nav__divider"><hr class="mt-0" /></div>
                    <div class="filter--nav__cost_cont">
                      <p class="filter--nav__cost-info">
                        <small class="filter--nav__cost-label"
                          >Estimate for Drinks</small
                        >
                        <small class="filter--nav__cost-value"
                          >{functions.number_format(getAllPrice())}</small
                        >
                      </p>
                    </div>
                    <div class="filter--nav__divider"><hr /></div>

                    <div class="filter--nav__item_btn-cont">
                      {/* <button class="btn btn-outline btn-sm">
                        Prev <span>Category</span><i></i>
                      </button>
                      <button class="btn btn-outline btn-sm">
                        Next <span>Category</span><i></i>
                      </button> */}
                      <button type="button"
                      disabled = {selected.findIndex(x => x.name === selected_tab.name) === 0}
                      onClick={() => {
                        setTab(selected[selected.findIndex(x => x.name === selected_tab.name) - 1]);
                        return false;
                      }} class="btn btn-outline btn-sm">
                        Prev <span>Category</span><i></i>
                      </button>
                      <button 
                      disabled = {(selected.findIndex(x => x.name === selected_tab.name) + 1) >= selected.length}
                      type="button" onClick={() => {
                        setTab(selected[selected.findIndex(x => x.name === selected_tab.name) + 1]);
                        return false;
                      }} class="btn btn-outline btn-sm">
                        Next <span>Category</span><i></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <div
      class="modal fade budget-exceed-alert"
      id="budget-exceed-modal"
      tabindex="-1"
      aria-labelledby="budget-exceed-modal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="budget-exceed-modal__top-content">
              <h4 class="title">Notice</h4>
              <p class="md">
                We see you have changed the estimate; in order to help you get
                an approximate calculation we will need to adjust your budget to
                fit your request
              </p>
            </div>
            <div class="budget-exceed-modal__bottom-content">
              <div class="budget-exceed-modal__notice">
                <small
                  >No worries about your current estimate getting lost, to get
                  it back you can just re-input your budget and
                  re-calculate</small
                >
              </div>
              <div class="d-grid">
                <button
                  type="button"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Adjust budget & Proceed
                </button>
                <button type="button" class="btn">No, thanks</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <div
      class="modal fade more-event-service"
      id="more-event-service-modal"
      tabindex="-1"
      aria-labelledby="more-event-service-modal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
       <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="more-event-service-modal__top-content">
              <h4 class="title">Other Services</h4>
              <p class="md">
                Determining the amount alcohol needed for an event is tricky
                because there is no perfect answer. Drinks.ng Event Calculator
                will make it much easier than simply guessing.
              </p>
            </div>
            <form class="more-event-service-modal__bottom-content">
              <ul>
                <li class="more-event-service-modal__item">
                  <div class="more-event-service-modal__service-cat">
                    <label
                      ><input
                        type="checkbox"
                        class="form-check-input more-event-service-modal__check"
                      />
                      <span class="more-event-service-modal__icon"
                        ><i class="icon icon-waiter"></i
                      ></span>
                      <p class="more-event-service-modal__title">Waiters</p>
                      <small class="more-event-service-modal__description"
                        >Determining the amount alcohol needed for an event is
                        tricky.
                      </small>
                    </label>
                    <div class="product-qty-count top">
                      <input
                        type="hidden"
                        class="--category"
                        value="90"
                        id="qty-11"
                      />
                      <span class="--btl-count" id="val-11"
                        ><span class="--value">90</span
                        ><small class="sm">Persons</small></span
                      >
                      <span class="--action">
                        <span class="--minus" data-ref="11"
                          ><i class="icon icon-minus"></i
                        ></span>
                        <span class="--plus" data-ref="11"
                          ><i class="icon icon-add"></i
                        ></span>
                      </span>
                    </div>
                  </div>
                </li>
                <li class="more-event-service-modal__item">
                  <label class="more-event-service-modal__service-cat">
                    <input
                      type="checkbox"
                      class="form-check-input more-event-service-modal__check"
                    />
                    <span class="more-event-service-modal__icon"
                      ><i class="icon icon-bar"></i
                    ></span>
                    <p class="more-event-service-modal__title">Mobile Bar</p>
                    <small class="more-event-service-modal__description"
                      >Determining the amount alcohol needed for an event is
                      tricky.
                    </small>
                  </label>
                </li>
                <li class="more-event-service-modal__item">
                  <label class="more-event-service-modal__service-cat">
                    <input
                      type="checkbox"
                      class="form-check-input more-event-service-modal__check"
                    />
                    <span class="more-event-service-modal__icon"
                      ><i class="icon icon-cooler"></i
                    ></span>
                    <p class="more-event-service-modal__title">
                      Cooling Service
                    </p>
                    <small class="more-event-service-modal__description"
                      >Determining the amount alcohol needed for an event is
                      tricky.
                    </small>
                  </label>
                </li>
                <li class="more-event-service-modal__item">
                  <label class="more-event-service-modal__service-cat">
                    <input
                      type="checkbox"
                      class="form-check-input more-event-service-modal__check"
                    />
                    <span class="more-event-service-modal__icon"
                      ><i class="icon icon-dorime"></i
                    ></span>
                    <p class="more-event-service-modal__title">
                      Dorima Ceremony
                    </p>
                    <small class="sm more-event-service-modal__description"
                      >Determining the amount alcohol needed for an event is
                      tricky.
                    </small>
                  </label>
                </li>
              </ul>
            </form>
          </div>

          <div class="modal-footer">
            <div class="modal-footer">
            <button type="button" 
              data-bs-dismiss="modal"
              onClick={() => props.history.push('/checkout')}
              class="btn btn-outline">No, Thanks</button>
            <button type="button" 
              data-bs-dismiss="modal"
              onClick={() => props.history.push('/checkout')}
              class="btn btn-success">
              Continue To Checkout
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>






      </React.Fragment>
  )
}

