import axios from "axios";
import config from "../../../config";

export const URL = config.URL+"api/help";

// CREATE =>  POST: add a new data to the server
export function addNewData(data, queryParams, type = 'cart') {
  if (type === 'cart') {
    return axios.post(`${config.URL}cart/add-to-cart/${queryParams}/`, data );  
  } else if (type === 'remove') {
    // return axios.post(`${config.URL}cart/remove-item/${queryParams}/`, data );  
    return axios.delete(`${config.URL}order/${queryParams}/`);  
  } else if (type === 'email') {
    return axios.post(`${config.URL}calculator-estimate-email/`, data );  
  } else if (type === 'email2') {
    return axios.post(`${config.URL}calculator-estimate-email_2/`, data );  
  }
  return axios.post(`${config.URL}product-category${queryParams}`, data );
}

// READ
export function getAllDatas() {
  return axios.get(URL);
}

export function getSataById(dataId) {
  return axios.get(`${URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'categories') {
    return axios.get(`${config.URL}product-category${queryParams}`);
  } else if (type === 'products') {
    return axios.get(`${config.URL}stock${queryParams}`);
  } else if (type === 'order') {
    return axios.get(`${config.URL}order/${queryParams}`);
  } 
}

// UPDATE => PUT: update the data on the server
export function updateData(data) {
  return axios.put(`${URL}/${data.id}`, { data });
}

// UPDATE Status
export function updateStatusForDatas(ids, status) {
  return axios.post(`${URL}/updateStatusFordatas`, {
    ids,
    status
  });
}

// DELETE => delete the data from the server
export function deleteData(dataId) {
  return axios.delete(`${URL}/${dataId}`);
}

// DELETE datas by ids
export function deleteDatas(ids) {
  return axios.post(`${URL}/deletedatas`, { ids });
}
