import axios from "axios";

import config from "../../../config";

export const URL = config.URL;

export const LOGIN_URL = URL+"auth/token/login/";
export const REGISTER_URL = URL+"users/";
export const REQUEST_PASSWORD_URL = URL+"api/auth/forgot-password";
export const ME_URL = URL+"users/me/";
export const EMAIL_VERIFY = URL+'auth/user/email';
export const FORGOT_URL = URL+'auth/forgot';
export const CHANGE_PASSWORD = URL+'auth/forgot';

export function login(email, password) {
  return axios.post(LOGIN_URL, { email: email, password: password });
}

export function forgot(email, type) {
  return axios.post(FORGOT_URL, { email, type });
}

export function editUserData(body) {
  return axios.put(URL+'auth/user', body);
}

export function passwordd(body) {
  return axios.put(URL+'auth/user/password', body);
}

export function register(email, password) {
  return axios.post(REGISTER_URL, { email: email, password: password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function verify_email(body) {
  return axios.post(EMAIL_VERIFY, body);
}


export function changePassword(body) {
  return axios.post(URL+'auth/user/change_pass', body);
}

export function resend_token(body) {
  return axios.post(URL+'auth/user/resendverify', body);
}


export function edit_user(body) {
  return axios.put(URL+'auth/user', body);
}


export function uploadFile(body, type) {
  if (type === 'profile_pic') {
      return axios({
        method: "POST",
        url: URL+'auth/user/profile_pic',
        data: body,
        headers: { "Content-Type": "multipart/form-data" },
      });
  } else {
      return axios({
        method: "POST",
        url: URL+'auth/user/verification',
        data: body,
        headers: { "Content-Type": "multipart/form-data" },
      });
  }
}



export function getUserByToken(token) {
  return axios.get(ME_URL);
}
