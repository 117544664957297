import React from "react";
import ReactDOM from 'react-dom/client';
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

// import Home from "./pages/App/home"

const PUBLIC_URL = process.env.URL;
_redux.setupAxios(axios, store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      {/* <BrowserRouter> */}
        <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        
      {/* </BrowserRouter> */}
   </React.StrictMode>
);
