import React from "react"


export default function SVG_Header() {
    return (
     


        <section className="banner">
                <div className="container">
                <div className="row">
                    <div className="col-xl-5 offset-xl-1 col-lg-6 offset-lg-0 col-md-11 --txt-content">
                    <h2 className="title">Knowing the amount of drinks needed for your events has just been made easier</h2>
                    <p>Determining the amount alcohol needed for an event is tricky because there is no perfect answer. Drinks.ng
                        Event Calculator will make it much easier than simply guessing.</p>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                    <div className="--bg-content">
                    
                        <div className="--bottle-container">
                        <svg width="561" height="293" viewBox="0 0 561 293" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className="BTL">
                            <path
                            d="M97.9889 70.6837V70.5H95.5511H93.2146H90.7768V70.6837C86.0326 71.3952 82.3454 74.0008 80.9255 75.4207L80.7791 75.5671V75.7742V82.9356V83.4356H81.2791H83.1662V84.8227H81.2791H80.7791V85.3227V93.6776V94.1776H81.2791H83.1662V95.7557L81.0555 96.811L80.767 96.9553L80.7794 97.2775C80.9781 102.443 81.1569 110.491 81.0476 117.84C80.993 121.515 80.8664 125.009 80.6351 127.878C80.4019 130.771 80.0664 132.96 79.6212 134.073C79.3557 134.737 78.8243 135.486 78.0578 136.331C77.2957 137.17 76.3285 138.074 75.2228 139.049C74.4709 139.713 73.6533 140.411 72.798 141.141C71.157 142.541 69.3775 144.06 67.6576 145.681C62.4303 150.61 57.4852 156.725 58.1031 164.139C58.9832 174.7 61.2843 207.726 62.5734 226.534C62.7632 229.302 65.0634 231.437 67.8354 231.437H84.8091H103.957H120.93C123.702 231.437 126.003 229.302 126.192 226.534C127.481 207.726 129.782 174.7 130.663 164.139C131.28 156.725 126.335 150.61 121.108 145.681C119.388 144.06 117.609 142.541 115.968 141.141C115.112 140.411 114.295 139.713 113.543 139.049C112.437 138.074 111.47 137.17 110.708 136.331C109.941 135.486 109.41 134.737 109.145 134.073C108.699 132.96 108.364 130.771 108.131 127.878C107.899 125.009 107.773 121.515 107.718 117.84C107.609 110.491 107.788 102.443 107.986 97.2775L107.999 96.9553L107.71 96.811L105.6 95.7557V94.1776H107.487H107.987V93.6776V85.3227V84.8227H107.487H105.6V83.4356H107.487H107.987V82.9356V75.7742V75.5671L107.84 75.4207C106.42 74.0008 102.733 71.3952 97.9889 70.6837Z"
                            fill="white" stroke="#CBCBD9" />
                            <mask id="mask0_182_518" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="58" y="71" width="73"
                            height="160">
                            <path
                                d="M84.8091 230.937H103.957H120.93C123.441 230.937 125.522 229.004 125.693 226.499C126.982 207.693 129.284 174.664 130.164 164.097C131.358 149.775 111.067 140.226 108.68 134.259C106.771 129.484 107.089 107.602 107.487 97.2583L105.1 96.0647V93.6776H107.487V85.3227H105.1V82.9356H107.487V75.7742C105.895 74.1828 101.28 71 95.5511 71H93.2146C87.4856 71 82.8705 74.1828 81.2791 75.7742V82.9356H83.6662V85.3227H81.2791V93.6776H83.6662V96.0647L81.2791 97.2583C81.6769 107.602 81.9951 129.484 80.0854 134.259C77.6983 140.226 57.4078 149.775 58.6014 164.097C59.4819 174.664 61.7833 207.693 63.0723 226.499C63.2439 229.004 65.3247 230.937 67.8354 230.937H84.8091Z"
                                fill="#411711" />
                            </mask>
                            <g mask="url(#mask0_182_518)">
                            <path
                                d="M88.496 229.742H106.992H123.388C125.814 229.742 127.824 227.81 127.99 225.305C129.235 206.499 131.458 173.469 132.308 162.903C133.461 148.58 113.861 139.032 111.555 133.064C109.71 128.29 110.018 106.408 110.402 96.0639L108.096 94.8704V92.4833H110.402V84.1284H108.096V81.7412H110.402V74.5799C108.865 72.9885 104.407 69.8057 98.8725 69.8057H96.6156C91.0814 69.8057 86.6233 72.9885 85.086 74.5799V81.7412H87.3919V84.1284H85.086V92.4833H87.3919V94.8704L85.086 96.0639C85.4703 106.408 85.7777 130.677 83.933 135.451C81.627 141.419 62.0268 150.968 63.1797 165.29C64.0303 175.856 66.2534 206.499 67.4985 225.305C67.6644 227.81 69.6744 229.742 72.0997 229.742H88.496Z"
                                fill="#F4F4F9" />
                            <rect x="78.8926" y="119.935" width="31.0325" height="9.54846" fill="#E5E5F1" />
                            <rect width="31.0325" height="2.38711" transform="matrix(1 0 0 -1 78.8926 119.935)" fill="white" />
                            <rect x="78.668" y="95.9985" width="31.6656" height="21.6659" fill="#E5E5F1" />
                            <rect x="78.8926" y="100.838" width="31.0325" height="2.38711" fill="white" />
                            <rect x="85.334" y="67.6663" width="24.9991" height="28.3323" fill="#E5E5F1" />
                            <path
                                d="M117.087 166.484C112.824 164.934 102.594 161.873 95.0066 161.716C87.4189 161.873 77.1893 164.934 72.9258 166.484C72.9258 173.646 74.3581 183.91 75.3129 191.549C76.2403 198.968 88.1272 213.369 95.0066 217.471C101.886 213.369 113.773 198.968 114.7 191.549C115.655 183.91 117.087 173.646 117.087 166.484Z"
                                fill="#E5E5F1" />
                            <path
                                d="M94.4098 161.71C102.049 161.71 112.711 164.893 117.087 166.484C117.087 173.646 115.655 183.91 114.7 191.549C113.745 199.188 101.173 214.226 94.4098 217.807M95.6034 161.71C87.9646 161.71 77.3022 164.893 72.9258 166.484C72.9258 173.646 74.3581 183.91 75.3129 191.549C76.2677 199.188 88.8399 214.226 95.6034 217.807"
                                stroke="#CBCBD9" />
                            <ellipse cx="94.4065" cy="232.13" rx="25.0647" ry="7.16134" fill="#E4E4EC" />
                            <path
                                d="M95.0033 187.989C86.4507 188.178 74.5378 189.613 69.3418 190.355L76.5031 193.936L69.3418 203.484C74.8684 202.379 87.1507 200.25 95.0033 199.941C102.856 200.25 115.138 202.379 120.665 203.484L113.503 193.936L120.665 190.355C115.469 189.613 103.556 188.178 95.0033 187.989Z"
                                fill="#CBCBD9" />
                            <path
                                d="M96.7936 187.968C88.2 187.968 74.9117 189.559 69.3418 190.355L76.5031 193.936L69.3418 203.484C75.3096 202.291 89.1549 199.904 96.7936 199.904M93.2129 187.968C101.807 187.968 115.095 189.559 120.665 190.355L113.503 193.936L120.665 203.484C114.697 202.291 100.852 199.904 93.2129 199.904"
                                stroke="#CBCBD9" strokeWidth="0.5" />
                            <rect x="93.2168" y="65.0317" width="42.9681" height="174.259" fill="black" fillOpacity="0.05" />
                            <path d="M82.002 95.9985H107.001" stroke="#CBCBD9" />
                            <path d="M82.002 84.3322H107.001" stroke="#CBCBD9" />
                            </g>
                            <path
                            d="M65.7613 160.516C65.7613 166.245 68.1485 206.269 69.3421 224.968C66.5571 205.474 62.6918 160.516 63.3743 158.129C65.4216 150.968 81.2777 140.226 83.6648 136.645C84.4605 135.452 86.8476 111.58 87.2454 100.838C87.6433 111.978 87.2452 135.452 84.8582 139.032C81.8975 143.474 65.7613 153.355 65.7613 160.516Z"
                            fill="white" />
                            <path
                            d="M29.9871 291.511H59.9392H84.6056C86.8279 291.511 88.6294 289.71 88.6294 287.488V261.059C88.6294 258.837 86.8279 257.035 84.6056 257.035H83.3189L78.0841 154.084H78.8811C81.2897 154.084 83.1587 151.982 82.8773 149.59L80.1334 126.267C79.9201 124.454 78.5132 123.012 76.7062 122.753L67.1581 121.389C65.7195 120.516 63.6034 118.981 61.8399 117.161C60.0189 115.282 58.6773 113.204 58.6773 111.299V72.6886L60.5147 69.9325C61.5786 68.3366 61.3682 66.2115 60.0119 64.8553L58.6773 63.5206V54.9183C58.6773 52.696 56.8758 50.8945 54.6535 50.8945H52.8916H37.0346H35.2727C33.0505 50.8945 31.249 52.696 31.249 54.9183V63.5206L29.9143 64.8553C28.5581 66.2115 28.3476 68.3366 29.4116 69.9325L31.249 72.6886V111.299C31.249 113.204 29.9073 115.282 28.0864 117.161C26.3229 118.981 24.2067 120.516 22.7682 121.389L13.2201 122.753C11.4131 123.012 10.0062 124.454 9.7929 126.267L7.04896 149.59C6.76754 151.982 8.63657 154.084 11.0452 154.084H11.8421L6.60732 257.035H5.32065C3.09838 257.035 1.29688 258.837 1.29688 261.059V287.488C1.29688 289.71 3.09838 291.511 5.32065 291.511H29.9871Z"
                            fill="white" stroke="#CBCBD9" />
                            <mask id="mask1_182_518" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="1" y="51" width="88"
                            height="241">
                            <path
                                d="M29.9871 291.011H59.9392H84.6056C86.5517 291.011 88.1294 289.434 88.1294 287.488V261.059C88.1294 259.113 86.5517 257.535 84.6056 257.535H82.8437L77.5581 153.584H78.8811C80.9904 153.584 82.6272 151.743 82.3807 149.649L79.6368 126.325C79.45 124.737 78.218 123.475 76.6355 123.248L66.9867 121.87C64.0503 120.108 58.1773 115.527 58.1773 111.299V72.5372L60.0986 69.6552C61.0304 68.2575 60.8461 66.3966 59.6584 65.2088L58.1773 63.7277V54.9183C58.1773 52.9722 56.5996 51.3945 54.6535 51.3945H52.8916H37.0346H35.2727C33.3266 51.3945 31.749 52.9722 31.749 54.9183V63.7277L30.2679 65.2088C29.0802 66.3966 28.8959 68.2575 29.8276 69.6552L31.749 72.5372V111.299C31.749 115.527 25.876 120.108 22.9395 121.87L13.2908 123.248C11.7083 123.475 10.4763 124.737 10.2895 126.325L7.54554 149.649C7.29908 151.743 8.93587 153.584 11.0452 153.584H12.3682L7.08254 257.535H5.32065C3.37452 257.535 1.79688 259.113 1.79688 261.059V287.488C1.79688 289.434 3.37452 291.011 5.32065 291.011H29.9871Z"
                                fill="#C4C4C4" />
                            </mask>
                            <g mask="url(#mask1_182_518)">
                            <path
                                d="M34.2664 293.15H64.2185H88.8849C90.831 293.15 92.4087 291.573 92.4087 289.627V263.198C92.4087 261.252 90.831 259.674 88.8849 259.674H87.123L81.8374 155.723H83.1604C85.2697 155.723 86.9065 153.882 86.66 151.788L83.9161 128.464C83.7293 126.877 82.4973 125.614 80.9148 125.387L71.266 124.009C68.3295 122.247 62.4566 117.666 62.4566 113.438V74.6762L64.3779 71.7942C65.3097 70.3966 65.1254 68.5356 63.9377 67.3479L62.4566 65.8668V57.0573C62.4566 55.1112 60.8789 53.5336 58.9328 53.5336H57.1709H41.3139H39.552C37.6059 53.5336 36.0283 55.1112 36.0283 57.0573V65.8668L34.5472 67.3479C33.3594 68.5356 33.1752 70.3966 34.1069 71.7942L36.0283 74.6762V113.438C36.0283 117.666 30.1553 122.247 27.2188 124.009L17.5701 125.387C15.9876 125.614 14.7555 126.877 14.5688 128.464L11.8248 151.788C11.5784 153.882 13.2152 155.723 15.3245 155.723H16.6475L11.3618 259.674H9.59995C7.65382 259.674 6.07617 261.252 6.07617 263.198V289.627C6.07617 291.573 7.65382 293.15 9.59995 293.15H34.2664Z"
                                fill="#F4F4F9" />
                            <path d="M36.0301 98.9644H120.601V130.678L21.0541 132.818L19.9844 130.081L36.0301 118.786V98.9644Z"
                                fill="#E5E5F1" />
                            <rect x="35.7754" y="46.1083" width="42.2853" height="17.6189" fill="#E5E5F1" />
                            <rect width="42.2853" height="7.04755" transform="matrix(1 0 0 -1 35.7754 70.7747)" fill="#CBCBD9" />
                            <rect x="35.7754" y="70.7747" width="42.2853" height="42.2853" fill="#CBCBD9" />
                            <rect width="42.2853" height="3.52378" transform="matrix(1 0 0 -1 22.9395 77.8217)" fill="white" />
                            <rect x="16.3945" y="155.846" width="55.3804" height="101.189" rx="0.5" fill="#E5E5F1"
                                stroke="#CBCBD9" />
                            <ellipse cx="44.0858" cy="294.535" rx="38.7615" ry="15.857" fill="#EAEAF2" />
                            <rect x="35.1504" y="88.3938" width="56.3804" height="21.1427" fill="#F4F4F9" />
                            <rect x="22.9395" y="106.013" width="42.2853" height="3.52378" fill="white" />
                            <path
                                d="M37.0361 84.87C37.0595 85.2447 37.0822 85.636 37.1038 86.0422C37.7067 96.1037 38.7325 104.775 37.0361 118.346C36.8057 120.189 24.1156 127.155 17.6553 128.917L15.8934 150.06L19.4172 151.822L14.1315 261.059L10.6078 265.464L7.08398 273.392V264.583L10.6078 259.297L15.8934 153.584L12.3696 151.822L14.1315 127.155C19.4172 125.981 29.2838 122.574 33.5123 118.346C37.5722 114.286 37.6258 95.8249 37.1038 86.0422C37.0806 85.6537 37.0579 85.2631 37.0361 84.87Z"
                                fill="white" />
                            <rect x="16.0945" y="180.211" width="55.9804" height="13.6951" fill="#CBCBD9" stroke="#CBCBD9"
                                strokeWidth="0.4" />
                            <rect opacity="0.05" x="44.082" y="46.1083" width="59.9042" height="257.236" fill="black" />
                            </g>
                            <path
                            d="M139.326 11.1258H140.752V12.0509H139.326H138.826V12.5509V20.2515V20.7515H139.326H140.752V79.9313C140.752 80.0737 140.705 80.2785 140.579 80.5593C140.455 80.8362 140.266 81.1587 140.012 81.5284C139.504 82.2688 138.769 83.1521 137.869 84.1727C137.276 84.8444 136.612 85.5745 135.902 86.3559C134.472 87.9293 132.852 89.7108 131.242 91.6434C126.423 97.4255 121.5 104.766 121.5 112.659V243.585C121.5 248.116 125.173 251.77 129.701 251.77H148.952H158.578H177.829C182.357 251.77 186.03 248.116 186.03 243.585V112.659C186.03 104.766 181.107 97.4255 176.288 91.6434C174.678 89.7108 173.058 87.9293 171.628 86.3559C170.918 85.5745 170.254 84.8444 169.662 84.1727C168.761 83.1521 168.026 82.2688 167.518 81.5284C167.264 81.1587 167.075 80.8362 166.951 80.5593C166.825 80.2785 166.779 80.0737 166.779 79.9313V20.7515H168.204H168.704V20.2515V12.5509V12.0509H168.204H166.779V11.1258H168.204H168.704V10.6258V1V0.5H168.204H156.653H150.877H139.326H138.826V1V10.6258V11.1258H139.326Z"
                            fill="white" stroke="#CBCBD9" />
                            <mask id="mask2_182_518" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="122" y="1" width="64"
                            height="251">
                            <path
                                d="M148.952 251.27H158.578H177.829C182.082 251.27 185.53 247.838 185.53 243.586V112.659C185.53 97.2577 166.279 83.7816 166.279 79.9313V20.2515H168.204V12.5509H166.279V10.6258H168.204V1H156.653H150.877H139.326V10.6258H141.252V12.5509H139.326V20.2515H141.252V79.9313C141.252 83.7816 122 97.2577 122 112.659V243.586C122 247.838 125.448 251.27 129.701 251.27H148.952Z"
                                fill="#E4C585" />
                            </mask>
                            <g mask="url(#mask2_182_518)">
                            <path
                                d="M155.103 251.021H164.728H183.98C188.233 251.021 191.68 247.65 191.68 243.47V114.818C191.68 99.6843 172.429 86.4423 172.429 82.6589V24.0158H174.354V16.4489H172.429V14.5572H174.354V5.09863H162.803H157.028H145.477V14.5572H147.402V16.4489H145.477V24.0158H147.402V82.6589C147.402 86.4423 128.15 99.6843 128.15 114.818V243.47C128.15 247.65 131.598 251.021 135.851 251.021H155.103Z"
                                fill="#F4F4F9" />
                            <rect x="144.174" y="-14.401" width="50.054" height="69.3055" fill="#E5E5F1" />
                            <rect x="142.496" y="-15.3948" width="49.1846" height="26.6416" fill="#E5E5F1" />
                            <path
                                d="M125.802 131.757L125.6 131.907V132.159V201.837V202.337H126.1H181.432H181.932V201.837V132.159V131.907L181.73 131.757L162.992 117.877C157.511 113.817 150.021 113.817 144.54 117.877L125.802 131.757Z"
                                fill="#E5E5F1" stroke="#CBCBD9" />
                            <circle cx="152.807" cy="153.087" r="9.62577" fill="#CBCBD9" />
                            <ellipse cx="153.767" cy="252.047" rx="23.5676" ry="9.22211" fill="#EAEAF2" />
                            <path opacity="0.4" d="M152.807 -10.5519H189.385V255.119H152.807V-10.5519Z" fill="black"
                                fillOpacity="0.1" />
                            <path d="M138.541 10.2222H169.281" stroke="#CBCBD9" />
                            <path d="M138.541 20.4689H169.281" stroke="#CBCBD9" />
                            <path
                                d="M131.503 108.809C131.503 116.51 130.219 200.575 129.577 241.645C128.294 199.933 127.652 114.584 127.652 106.884C127.652 97.2581 135.105 92.7245 140.88 83.0987C145.347 75.6533 145.375 49.0501 144.792 36.8549C144.77 36.4441 144.749 36.0433 144.73 35.6532C144.752 36.036 144.772 36.437 144.792 36.8549C145.552 50.6362 148.469 75.6768 144.73 85.0239C140.88 94.6497 131.503 99.1833 131.503 108.809Z"
                                fill="white" />
                            </g>
                            <path
                            d="M434.745 116.031C434.745 106.166 432.103 93.5649 430.637 87.8931H431.407H431.907V87.3931V71.9834H432.826H433.326V71.4834V58.7556V58.2556H432.826H421.474H417.217H405.866H405.366V58.7556V71.4834V71.9834H405.866H406.785V87.3931V87.8931H407.285H408.055C406.588 93.5649 403.947 106.166 403.947 116.031C403.947 125.871 404.828 133.547 405.32 136.491C401.994 138.912 397.146 142.897 393.085 147.23C391.002 149.451 389.115 151.777 387.745 154.039C386.381 156.293 385.5 158.535 385.5 160.578V251.268C385.5 256.761 389.484 261.309 394.514 261.309H411.542H427.15H444.178C449.208 261.309 453.192 256.761 453.192 251.268V160.578C453.192 158.535 452.311 156.293 450.947 154.039C449.577 151.777 447.69 149.451 445.607 147.23C441.545 142.897 436.698 138.912 433.372 136.491C433.864 133.547 434.745 125.871 434.745 116.031Z"
                            fill="white" stroke="#CBCBD9" />
                            <mask id="mask3_182_518" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="386" y="58" width="67"
                            height="203">
                            <path
                                d="M411.542 260.809H427.15H444.178C448.88 260.809 452.692 256.54 452.692 251.268V160.578C452.692 152.941 439.448 141.486 432.826 136.713C433.299 134.062 434.245 126.213 434.245 116.031C434.245 105.848 431.407 92.6963 429.988 87.3931H431.407V71.4834H432.826V58.7556H421.474H417.217H405.866V71.4834H407.285V87.3931H408.704C407.285 92.6963 404.447 105.848 404.447 116.031C404.447 126.213 405.393 134.062 405.866 136.713C399.244 141.486 386 152.941 386 160.578V251.268C386 256.54 389.812 260.809 394.514 260.809H411.542Z"
                                fill="white" />
                            </mask>
                            <g mask="url(#mask3_182_518)">
                            <path
                                d="M421.663 256.931H437.604H454.994C459.796 256.931 463.689 252.763 463.689 247.615V159.068C463.689 151.611 450.163 140.427 443.401 135.767C443.884 133.178 444.85 125.514 444.85 115.573C444.85 105.631 441.951 92.7896 440.502 87.6116H443.401V72.0777H446.299V59.6506H431.807H427.46H412.968V72.0777H415.866V87.6116H418.765C417.316 92.7896 414.417 105.631 414.417 115.573C414.417 125.514 415.383 133.178 415.866 135.767C409.104 140.427 395.578 151.611 395.578 159.068V247.615C395.578 252.763 399.471 256.931 404.273 256.931H421.663Z"
                                fill="#F4F4F9" />
                            <ellipse cx="420.674" cy="260.007" rx="24.4764" ry="9.14775" fill="#EAEAF2" />
                            <g 
                                style = {{mixBlendMode: "multiply"}}
                            
                            //   style="mix-blend-mode:multiply"
                            >
                                <rect x="381.742" y="119.215" width="73.7867" height="25.4555" fill="#F4F4F9" />
                            </g>
                            <rect x="410.299" y="49.2104" width="73.7867" height="70.0027" fill="#E5E5F1" />
                            <path d="M404.357 71.3344H434.953" stroke="#CBCBD9" />
                            <path d="M404.357 87.343H434.953" stroke="#CBCBD9" />
                            <rect width="73.7867" height="76.3666" transform="matrix(1 0 0 -1 381.742 243.309)" fill="#E5E5F1" />
                            <rect opacity="0.04" x="418.639" y="55.5747" width="42.5692" height="206.826" fill="black" />
                            </g>
                            <path
                            d="M388.215 244.899C388.215 252.536 391.999 254.445 393.891 254.445H396.729C394.837 254.445 391.053 252.536 391.053 244.899V158.987C391.053 155.168 402.833 143.489 409.454 137.655C408.508 133.413 408.08 124.363 408.08 116.726C408.08 107.181 407.46 101.513 407.46 96.7398C407.46 101.513 405.243 107.181 405.243 116.726C405.243 124.363 405.67 133.413 406.616 137.655C399.995 143.489 388.215 155.168 388.215 158.987V244.899Z"
                            fill="white" />
                            <path
                            d="M398.771 189.202C415.383 186.005 424.73 186.053 441.341 189.202L438.503 201.929C422.325 199.886 418.721 199.796 401.609 201.929L398.771 189.202Z"
                            fill="#CBCBD9" />
                            <path
                            d="M404.745 126.031C404.745 116.166 402.103 103.565 400.637 97.8931H401.407H401.907V97.3931V81.9834H402.826H403.326V81.4834V68.7556V68.2556H402.826H391.474H387.217H375.866H375.366V68.7556V81.4834V81.9834H375.866H376.785V97.3931V97.8931H377.285H378.055C376.588 103.565 373.947 116.166 373.947 126.031C373.947 135.871 374.828 143.547 375.32 146.491C371.994 148.912 367.146 152.897 363.085 157.23C361.002 159.451 359.115 161.777 357.745 164.039C356.381 166.293 355.5 168.535 355.5 170.578V261.268C355.5 266.761 359.484 271.309 364.514 271.309H381.542H397.15H414.178C419.208 271.309 423.192 266.761 423.192 261.268V170.578C423.192 168.535 422.311 166.293 420.947 164.039C419.577 161.777 417.69 159.451 415.607 157.23C411.545 152.897 406.698 148.912 403.372 146.491C403.864 143.547 404.745 135.871 404.745 126.031Z"
                            fill="white" stroke="#CBCBD9" />
                            <mask id="mask4_182_518" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="356" y="68" width="67"
                            height="203">
                            <path
                                d="M381.542 270.809H397.15H414.178C418.88 270.809 422.692 266.54 422.692 261.268V170.578C422.692 162.941 409.448 151.486 402.826 146.713C403.299 144.062 404.245 136.213 404.245 126.031C404.245 115.848 401.407 102.696 399.988 97.3931H401.407V81.4834H402.826V68.7556H391.474H387.217H375.866V81.4834H377.285V97.3931H378.704C377.285 102.696 374.447 115.848 374.447 126.031C374.447 136.213 375.393 144.062 375.866 146.713C369.244 151.486 356 162.941 356 170.578V261.268C356 266.54 359.812 270.809 364.514 270.809H381.542Z"
                                fill="white" />
                            </mask>
                            <g mask="url(#mask4_182_518)">
                            <path
                                d="M391.663 266.931H407.604H424.994C429.796 266.931 433.689 262.763 433.689 257.615V169.068C433.689 161.611 420.163 150.427 413.401 145.767C413.884 143.178 414.85 135.514 414.85 125.573C414.85 115.631 411.951 102.79 410.502 97.6116H413.401V82.0777H416.299V69.6506H401.807H397.46H382.968V82.0777H385.866V97.6116H388.765C387.316 102.79 384.417 115.631 384.417 125.573C384.417 135.514 385.383 143.178 385.866 145.767C379.104 150.427 365.578 161.611 365.578 169.068V257.615C365.578 262.763 369.471 266.931 374.273 266.931H391.663Z"
                                fill="#F4F4F9" />
                            <ellipse cx="390.674" cy="270.007" rx="24.4764" ry="9.14775" fill="#EAEAF2" />
                            <g 
                            style = {{mixBlendMode: "multiply"}}
                            //   style="mix-blend-mode:multiply"
                            >
                                <rect x="351.742" y="129.215" width="73.7867" height="25.4555" fill="#F4F4F9" />
                            </g>
                            <rect x="380.299" y="59.2104" width="73.7867" height="70.0027" fill="#E5E5F1" />
                            <path d="M374.357 81.3344H404.953" stroke="#CBCBD9" />
                            <path d="M374.357 97.343H404.953" stroke="#CBCBD9" />
                            <rect width="73.7867" height="76.3666" transform="matrix(1 0 0 -1 351.742 253.309)" fill="#E5E5F1" />
                            <rect opacity="0.04" x="388.639" y="65.5747" width="42.5692" height="206.826" fill="black" />
                            </g>
                            <path
                            d="M358.215 254.899C358.215 262.536 361.999 264.445 363.891 264.445H366.729C364.837 264.445 361.053 262.536 361.053 254.899V168.987C361.053 165.168 372.833 153.489 379.454 147.655C378.508 143.413 378.08 134.363 378.08 126.726C378.08 117.181 377.46 111.513 377.46 106.74C377.46 111.513 375.243 117.181 375.243 126.726C375.243 134.363 375.67 143.413 376.616 147.655C369.995 153.489 358.215 165.168 358.215 168.987V254.899Z"
                            fill="white" />
                            <path
                            d="M368.771 199.202C385.383 196.005 394.73 196.053 411.341 199.202L408.503 211.929C392.325 209.886 388.721 209.796 371.609 211.929L368.771 199.202Z"
                            fill="#CBCBD9" />
                            <path
                            d="M461.092 12.2417H462.508V13.39H461.092H460.592V13.89V22.4833V22.9833H461.092H462.508V89.0817C462.508 89.4671 462.28 90.0667 461.759 90.9187C461.251 91.7481 460.518 92.7359 459.622 93.8748C459.031 94.6253 458.37 95.4404 457.663 96.3127C456.24 98.068 454.629 100.055 453.027 102.211C448.228 108.668 443.348 116.837 443.348 125.603V271.708C443.348 276.676 446.952 280.784 451.512 280.784H470.673H480.253H499.414C503.974 280.784 507.578 276.676 507.578 271.708V125.603C507.578 116.837 502.698 108.668 497.899 102.211C496.297 100.055 494.686 98.068 493.263 96.3127C492.556 95.4404 491.895 94.6253 491.304 93.8748C490.408 92.7359 489.675 91.7481 489.167 90.9187C488.646 90.0667 488.418 89.4671 488.418 89.0817V22.9833H489.834H490.334V22.4833V13.89V13.39H489.834H488.418V12.2417H489.834H490.334V11.7417V1V0.5H489.834H478.337H472.589H461.092H460.592V1V11.7417V12.2417H461.092Z"
                            fill="white" stroke="#CBCBD9" />
                            <mask id="mask5_182_518" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="443" y="1" width="65"
                            height="280">
                            <path
                                d="M470.673 280.284H480.253H499.414C503.647 280.284 507.078 276.454 507.078 271.708V125.603C507.078 108.417 487.918 93.3784 487.918 89.0817V22.4833H489.834V13.89H487.918V11.7417H489.834V1H478.337H472.589H461.092V11.7417H463.008V13.89H461.092V22.4833H463.008V89.0817C463.008 93.3784 443.848 108.417 443.848 125.603V271.708C443.848 276.454 447.279 280.284 451.512 280.284H470.673Z"
                                fill="#1D1D1B" />
                            </mask>
                            <g mask="url(#mask5_182_518)">
                            <path
                                d="M474.757 280.284H484.337H503.498C507.731 280.284 511.162 276.454 511.162 271.708V125.603C511.162 108.417 492.002 93.3784 492.002 89.0817V22.4833H493.918V13.89H492.002V11.7417H493.918V1H482.421H476.673H465.176V11.7417H467.092V13.89H465.176V22.4833H467.092V89.0817C467.092 93.3784 447.932 108.417 447.932 125.603V271.708C447.932 276.454 451.363 280.284 455.596 280.284H474.757Z"
                                fill="#F4F4F9" />
                            <ellipse opacity="0.5" cx="472.672" cy="280.885" rx="22.7505" ry="13.6043" fill="#E5E5F1" />
                            <path
                                d="M452.376 121.096C452.376 129.646 450.942 222.975 450.225 268.572C448.791 222.263 448.074 127.508 448.074 118.959C448.074 108.272 458.829 101.86 465.281 91.1738C467.501 87.4979 467.944 79.0252 467.672 69.5407C466.694 56.9988 465.636 42.7104 465.281 35.3042C465.69 42.6143 467.31 56.9666 467.672 69.5407C468.669 82.3346 469.583 93.3112 469.583 93.3112C469.583 93.3112 452.376 110.41 452.376 121.096Z"
                                fill="white" />
                            <rect x="467.957" y="-16.1864" width="49.8182" height="77.34" fill="#E5E5F1" />
                            <rect x="449.467" y="147.141" width="49.9528" height="78.7558" fill="#E5E5F1" stroke="#CBCBD9" />
                            <rect x="-0.5" y="0.5" width="49.9528" height="10.1477" transform="matrix(1 0 0 -1 449.967 148.641)"
                                fill="#FDFDFD" stroke="#CBCBD9" />
                            <rect x="-0.5" y="0.5" width="49.9528" height="7.86081" transform="matrix(1 0 0 -1 449.967 233.257)"
                                fill="#FDFDFD" stroke="#CBCBD9" />
                            <ellipse cx="474.508" cy="170.718" rx="9.58042" ry="10.7417" fill="#CBCBD9" />
                            <path opacity="0.21" d="M474.51 -11.8911H510.915V284.579H474.51V-11.8911Z" fill="black"
                                fillOpacity="0.26" />
                            <path opacity="0.4" d="M464.393 3.28699H511.306V12.4347H464.393V3.28699Z" fill="#CBCBD9" />
                            <path d="M460.314 11.2913H490.91" stroke="#CBCBD9" />
                            </g>
                            <path
                            d="M517.398 56.3425L514.38 69.877L514.308 70.2024L514.53 70.4511L517.385 73.653C516.371 93.5762 514.365 134.302 514.365 139.741C514.365 141.226 513.524 142.996 512.113 145C510.717 146.982 508.849 149.079 506.946 151.212L506.929 151.231C505.046 153.343 503.129 155.491 501.686 157.541C500.253 159.577 499.198 161.633 499.198 163.548V276.185C499.198 278.201 500.832 279.835 502.848 279.835H521.081H537.765H555.999C558.015 279.835 559.649 278.201 559.649 276.185V163.548C559.649 161.633 558.594 159.577 557.161 157.541C555.717 155.491 553.801 153.343 551.917 151.231L551.901 151.212C549.998 149.079 548.129 146.982 546.733 145C545.323 142.996 544.482 141.226 544.482 139.741C544.482 134.302 542.476 93.5762 541.461 73.653L544.317 70.4511L544.539 70.2024L544.466 69.877L541.449 56.3425V51.3125V50.6625H540.799H534.732H524.115H518.048H517.398V51.3125V56.3425Z"
                            fill="white" stroke="#CBCBD9" strokeWidth="1.3" />
                            <mask id="mask6_182_518" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="499" y="51" width="60"
                            height="229">
                            <path
                                d="M521.081 279.185H537.765H555.999C557.656 279.185 558.999 277.842 558.999 276.185V163.548C558.999 156.746 543.832 146.543 543.832 139.741C543.832 134.299 541.81 93.2592 540.799 73.4196L543.832 70.0185L540.799 56.4141V51.3125H534.732H524.115H518.048V56.4141L515.015 70.0185L518.048 73.4196C517.037 93.2592 515.015 134.299 515.015 139.741C515.015 146.543 499.848 156.746 499.848 163.548V276.185C499.848 277.842 501.191 279.185 502.848 279.185H521.081Z"
                                fill="#F4F4F9" />
                            </mask>
                            <g mask="url(#mask6_182_518)">
                            <path
                                d="M527.648 274.083H543.904H561.594C563.25 274.083 564.594 272.74 564.594 271.083V163.624C564.594 157.126 549.816 147.38 549.816 140.882C549.816 135.684 547.845 96.4818 546.86 77.5305L549.816 74.2817L546.86 61.2865V56.4133H540.949H530.604H524.693V61.2865L521.737 74.2817L524.693 77.5305C523.708 96.4818 521.737 135.684 521.737 140.882C521.737 147.38 506.959 157.126 506.959 163.624V271.083C506.959 272.74 508.302 274.083 509.959 274.083H527.648Z"
                                fill="#F4F4F9" />
                            <rect width="51.5678" height="6.80217" transform="matrix(1 0 0 -1 519.197 58.7001)" fill="#EFECF2" />
                            <line x1="508.947" y1="72.9193" x2="548.382" y2="72.9193" stroke="#CBCBD9" />
                            <ellipse opacity="0.5" cx="528.667" cy="280.885" rx="22.7505" ry="13.6043" fill="#E5E5F1" />
                            <rect x="509.447" y="101.126" width="38.4342" height="26.2087" fill="#F4F4F9" stroke="#CBCBD9" />
                            <path
                                d="M538.931 114.23C538.931 118.871 537.756 123.056 535.874 126.07C533.99 129.088 531.431 130.886 528.664 130.886C525.896 130.886 523.338 129.088 521.453 126.07C519.572 123.056 518.397 118.871 518.397 114.23C518.397 109.59 519.572 105.405 521.453 102.391C523.338 99.3726 525.896 97.575 528.664 97.575C531.431 97.575 533.99 99.3726 535.874 102.391C537.756 105.405 538.931 109.59 538.931 114.23Z"
                                fill="#E5E5F1" stroke="white" strokeWidth="0.7" />
                            <rect x="506.416" y="172.55" width="47.5344" height="77.225" rx="12.5" fill="#F4F4F9"
                                stroke="#CBCBD9" />
                            <rect x="518.047" y="185.654" width="24.2672" height="51.0163" rx="12.1336" fill="#CBCBD9" />
                            <rect x="505.916" y="206.061" width="48.5344" height="13.6043" fill="#E5E5F1" />
                            </g>
                            <path
                            d="M508.947 166.949C506.52 172.391 505.913 234.971 505.913 265.581C504.397 234.404 501.97 170.35 504.397 163.548C507.43 155.045 513.497 153.345 518.047 143.141C521.687 134.979 523.608 96.66 524.114 78.5209C524.619 97.7937 524.72 138.38 521.08 146.543C516.53 156.746 511.98 160.147 508.947 166.949Z"
                            fill="white" />
                        </svg>
                        </div>


                        <svg width="297" height="433" viewBox="0 0 297 433" fill="none" xmlns="http://www.w3.org/2000/svg"
                        className="calculator">
                        <g filter="url(#filter0_bd_182_456)">
                            <path d="M34 30H263V379C263 387.837 255.837 395 247 395H50C41.1634 395 34 387.837 34 379V30Z"
                            fill="url(#paint0_linear_182_456)" shapeRendering="crispEdges" />
                            <path
                            d="M34.5 30.5H262.5V379C262.5 387.56 255.56 394.5 247 394.5H50C41.4396 394.5 34.5 387.56 34.5 379V30.5Z"
                            stroke="#F47352" shapeRendering="crispEdges" />
                        </g>
                        <path d="M197 299H263V379C263 387.837 255.837 395 247 395H197V299Z" fill="#F47352" />
                        <path
                            d="M234.976 336.936V338.856H218.656V336.936H234.976ZM234.976 343.464V345.352H218.656V343.464H234.976Z"
                            fill="white" />
                        <line x1="54" y1="154.5" x2="244" y2="154.5" stroke="#F47352" />
                        <path
                            d="M70 190.712C70 189.624 70.2453 188.648 70.736 187.784C71.2267 186.909 71.8933 186.227 72.736 185.736C73.5893 185.245 74.5333 185 75.568 185C76.784 185 77.8453 185.293 78.752 185.88C79.6587 186.467 80.32 187.299 80.736 188.376H78.992C78.6827 187.704 78.2347 187.187 77.648 186.824C77.072 186.461 76.3787 186.28 75.568 186.28C74.7893 186.28 74.0907 186.461 73.472 186.824C72.8533 187.187 72.368 187.704 72.016 188.376C71.664 189.037 71.488 189.816 71.488 190.712C71.488 191.597 71.664 192.376 72.016 193.048C72.368 193.709 72.8533 194.221 73.472 194.584C74.0907 194.947 74.7893 195.128 75.568 195.128C76.3787 195.128 77.072 194.952 77.648 194.6C78.2347 194.237 78.6827 193.72 78.992 193.048H80.736C80.32 194.115 79.6587 194.941 78.752 195.528C77.8453 196.104 76.784 196.392 75.568 196.392C74.5333 196.392 73.5893 196.152 72.736 195.672C71.8933 195.181 71.2267 194.504 70.736 193.64C70.2453 192.776 70 191.8 70 190.712Z"
                            fill="#636377" />
                        <path d="M79.1602 227.496L74.7122 237.928H73.2402L77.7522 227.64H71.5762V226.392H79.1602V227.496Z"
                            fill="#636377" />
                        <path
                            d="M70.8887 276.808V275.704L76.5047 267.928H78.2487V275.544H79.8487V276.808H78.2487V279.384H76.8087V276.808H70.8887ZM76.8727 269.448L72.5687 275.544H76.8727V269.448Z"
                            fill="#636377" />
                        <path d="M73.8633 310.712V309.384H76.8713V320.952H75.3993V310.712H73.8633Z" fill="#636377" />
                        <path
                            d="M71.3438 356.792C71.3438 354.957 71.6424 353.528 72.2398 352.504C72.8371 351.469 73.8824 350.952 75.3758 350.952C76.8584 350.952 77.8984 351.469 78.4957 352.504C79.0931 353.528 79.3918 354.957 79.3918 356.792C79.3918 358.659 79.0931 360.109 78.4957 361.144C77.8984 362.179 76.8584 362.696 75.3758 362.696C73.8824 362.696 72.8371 362.179 72.2398 361.144C71.6424 360.109 71.3438 358.659 71.3438 356.792ZM77.9518 356.792C77.9518 355.864 77.8878 355.08 77.7598 354.44C77.6424 353.789 77.3918 353.267 77.0078 352.872C76.6344 352.477 76.0904 352.28 75.3758 352.28C74.6504 352.28 74.0958 352.477 73.7118 352.872C73.3384 353.267 73.0878 353.789 72.9597 354.44C72.8424 355.08 72.7838 355.864 72.7838 356.792C72.7838 357.752 72.8424 358.557 72.9597 359.208C73.0878 359.859 73.3384 360.381 73.7118 360.776C74.0958 361.171 74.6504 361.368 75.3758 361.368C76.0904 361.368 76.6344 361.171 77.0078 360.776C77.3918 360.381 77.6424 359.859 77.7598 359.208C77.8878 358.557 77.9518 357.752 77.9518 356.792Z"
                            fill="#636377" />
                        <path
                            d="M126.682 186.678C126.301 186.678 125.98 186.548 125.72 186.288C125.477 186.028 125.356 185.707 125.356 185.326C125.356 184.945 125.477 184.633 125.72 184.39C125.98 184.13 126.301 184 126.682 184C127.046 184 127.349 184.13 127.592 184.39C127.852 184.633 127.982 184.945 127.982 185.326C127.982 185.707 127.852 186.028 127.592 186.288C127.349 186.548 127.046 186.678 126.682 186.678ZM133.234 190.162V191.696H120V190.162H133.234ZM126.682 197.832C126.301 197.832 125.98 197.702 125.72 197.442C125.477 197.182 125.356 196.861 125.356 196.48C125.356 196.099 125.477 195.787 125.72 195.544C125.98 195.284 126.301 195.154 126.682 195.154C127.046 195.154 127.349 195.284 127.592 195.544C127.852 195.787 127.982 196.099 127.982 196.48C127.982 196.861 127.852 197.182 127.592 197.442C127.349 197.702 127.046 197.832 126.682 197.832Z"
                            fill="#636377" stroke="#EFEFEF" strokeWidth="0.5" />
                        <path
                            d="M124.665 233.56C124.068 233.325 123.609 232.984 123.289 232.536C122.969 232.088 122.809 231.544 122.809 230.904C122.809 230.328 122.953 229.811 123.241 229.352C123.529 228.883 123.956 228.515 124.521 228.248C125.097 227.971 125.791 227.832 126.601 227.832C127.412 227.832 128.1 227.971 128.665 228.248C129.241 228.515 129.673 228.883 129.961 229.352C130.26 229.811 130.409 230.328 130.409 230.904C130.409 231.523 130.244 232.067 129.913 232.536C129.583 232.995 129.129 233.336 128.553 233.56C129.215 233.763 129.737 234.12 130.121 234.632C130.516 235.133 130.713 235.741 130.713 236.456C130.713 237.149 130.543 237.757 130.201 238.28C129.86 238.792 129.375 239.192 128.745 239.48C128.127 239.757 127.412 239.896 126.601 239.896C125.791 239.896 125.076 239.757 124.457 239.48C123.849 239.192 123.375 238.792 123.033 238.28C122.692 237.757 122.521 237.149 122.521 236.456C122.521 235.741 122.713 235.128 123.097 234.616C123.481 234.104 124.004 233.752 124.665 233.56ZM129.001 231.08C129.001 230.429 128.788 229.928 128.361 229.576C127.935 229.224 127.348 229.048 126.601 229.048C125.865 229.048 125.284 229.224 124.857 229.576C124.431 229.928 124.217 230.435 124.217 231.096C124.217 231.693 124.436 232.173 124.873 232.536C125.321 232.899 125.897 233.08 126.601 233.08C127.316 233.08 127.892 232.899 128.329 232.536C128.777 232.163 129.001 231.677 129.001 231.08ZM126.601 234.2C125.812 234.2 125.167 234.387 124.665 234.76C124.164 235.123 123.913 235.672 123.913 236.408C123.913 237.091 124.153 237.64 124.633 238.056C125.124 238.472 125.78 238.68 126.601 238.68C127.423 238.68 128.073 238.472 128.553 238.056C129.033 237.64 129.273 237.091 129.273 236.408C129.273 235.693 129.028 235.149 128.537 234.776C128.047 234.392 127.401 234.2 126.601 234.2Z"
                            fill="#636377" />
                        <path
                            d="M129.848 271.176H124.232V274.824C124.478 274.483 124.84 274.205 125.32 273.992C125.8 273.768 126.318 273.656 126.872 273.656C127.758 273.656 128.478 273.843 129.032 274.216C129.587 274.579 129.982 275.053 130.216 275.64C130.462 276.216 130.584 276.829 130.584 277.48C130.584 278.248 130.44 278.936 130.152 279.544C129.864 280.152 129.422 280.632 128.824 280.984C128.238 281.336 127.507 281.512 126.632 281.512C125.512 281.512 124.606 281.224 123.912 280.648C123.219 280.072 122.798 279.304 122.648 278.344H124.072C124.211 278.952 124.504 279.427 124.952 279.768C125.4 280.109 125.966 280.28 126.648 280.28C127.491 280.28 128.126 280.029 128.552 279.528C128.979 279.016 129.192 278.344 129.192 277.512C129.192 276.68 128.979 276.04 128.552 275.592C128.126 275.133 127.496 274.904 126.664 274.904C126.099 274.904 125.603 275.043 125.176 275.32C124.76 275.587 124.456 275.955 124.264 276.424H122.888V269.896H129.848V271.176Z"
                            fill="#636377" />
                        <path
                            d="M122.77 322.12C124.124 321.032 125.186 320.141 125.954 319.448C126.722 318.744 127.367 318.013 127.89 317.256C128.423 316.488 128.69 315.736 128.69 315C128.69 314.307 128.519 313.763 128.178 313.368C127.847 312.963 127.308 312.76 126.562 312.76C125.836 312.76 125.271 312.989 124.866 313.448C124.471 313.896 124.258 314.499 124.226 315.256H122.818C122.86 314.061 123.223 313.139 123.906 312.488C124.588 311.837 125.468 311.512 126.546 311.512C127.644 311.512 128.514 311.816 129.154 312.424C129.804 313.032 130.13 313.869 130.13 314.936C130.13 315.821 129.863 316.685 129.33 317.528C128.807 318.36 128.21 319.096 127.538 319.736C126.866 320.365 126.007 321.101 124.962 321.944H130.466V323.16H122.77V322.12Z"
                            fill="#636377" />
                        <path
                            d="M179.776 195.946L175.486 191.656L171.196 195.946L170 194.75L174.29 190.46L170.026 186.17L171.222 185L175.486 189.264L179.724 185.026L180.92 186.222L176.682 190.46L180.972 194.776L179.776 195.946Z"
                            fill="#636377" stroke="#F0F0F0" strokeWidth="0.5" />
                        <path
                            d="M173.255 234.634C173.361 235.242 173.607 235.711 173.991 236.042C174.385 236.373 174.913 236.538 175.575 236.538C176.46 236.538 177.111 236.191 177.527 235.498C177.953 234.805 178.156 233.637 178.135 231.994C177.911 232.474 177.537 232.853 177.015 233.13C176.492 233.397 175.911 233.53 175.271 233.53C174.556 233.53 173.916 233.386 173.351 233.098C172.796 232.799 172.359 232.367 172.039 231.802C171.719 231.237 171.559 230.554 171.559 229.754C171.559 228.613 171.889 227.695 172.551 227.002C173.212 226.298 174.151 225.946 175.367 225.946C176.86 225.946 177.905 226.431 178.503 227.402C179.111 228.373 179.415 229.818 179.415 231.738C179.415 233.082 179.292 234.191 179.047 235.066C178.812 235.941 178.407 236.607 177.831 237.066C177.265 237.525 176.487 237.754 175.495 237.754C174.407 237.754 173.559 237.461 172.951 236.874C172.343 236.287 171.996 235.541 171.911 234.634H173.255ZM175.511 232.298C176.225 232.298 176.812 232.079 177.271 231.642C177.729 231.194 177.959 230.591 177.959 229.834C177.959 229.034 177.735 228.389 177.287 227.898C176.839 227.407 176.209 227.162 175.399 227.162C174.652 227.162 174.055 227.397 173.607 227.866C173.169 228.335 172.951 228.954 172.951 229.722C172.951 230.501 173.169 231.125 173.607 231.594C174.044 232.063 174.679 232.298 175.511 232.298Z"
                            fill="#636377" />
                        <path
                            d="M177.751 270.746C177.516 269.562 176.785 268.97 175.559 268.97C174.609 268.97 173.9 269.338 173.431 270.074C172.961 270.799 172.732 271.999 172.743 273.674C172.988 273.119 173.393 272.687 173.959 272.378C174.535 272.058 175.175 271.898 175.879 271.898C176.977 271.898 177.852 272.239 178.503 272.922C179.164 273.605 179.495 274.549 179.495 275.754C179.495 276.479 179.351 277.13 179.063 277.706C178.785 278.282 178.359 278.741 177.783 279.082C177.217 279.423 176.529 279.594 175.719 279.594C174.62 279.594 173.761 279.349 173.143 278.858C172.524 278.367 172.092 277.69 171.847 276.826C171.601 275.962 171.479 274.895 171.479 273.626C171.479 269.711 172.844 267.754 175.575 267.754C176.62 267.754 177.441 268.037 178.039 268.602C178.636 269.167 178.988 269.882 179.095 270.746H177.751ZM175.575 273.13C175.116 273.13 174.684 273.226 174.279 273.418C173.873 273.599 173.543 273.882 173.287 274.266C173.041 274.639 172.919 275.098 172.919 275.642C172.919 276.453 173.153 277.114 173.623 277.626C174.092 278.127 174.764 278.378 175.639 278.378C176.385 278.378 176.977 278.149 177.415 277.69C177.863 277.221 178.087 276.591 178.087 275.802C178.087 274.97 177.873 274.319 177.447 273.85C177.02 273.37 176.396 273.13 175.575 273.13Z"
                            fill="#636377" />
                        <path
                            d="M171.83 312.73C171.905 311.749 172.284 310.981 172.966 310.426C173.649 309.871 174.534 309.594 175.622 309.594C176.348 309.594 176.972 309.727 177.494 309.994C178.028 310.25 178.428 310.602 178.694 311.05C178.972 311.498 179.11 312.005 179.11 312.57C179.11 313.231 178.918 313.802 178.534 314.282C178.161 314.762 177.67 315.071 177.062 315.21V315.29C177.756 315.461 178.305 315.797 178.71 316.298C179.116 316.799 179.318 317.455 179.318 318.266C179.318 318.874 179.18 319.423 178.902 319.914C178.625 320.394 178.209 320.773 177.654 321.05C177.1 321.327 176.433 321.466 175.654 321.466C174.524 321.466 173.596 321.173 172.87 320.586C172.145 319.989 171.74 319.146 171.654 318.058H173.062C173.137 318.698 173.398 319.221 173.846 319.626C174.294 320.031 174.892 320.234 175.638 320.234C176.385 320.234 176.95 320.042 177.334 319.658C177.729 319.263 177.926 318.757 177.926 318.138C177.926 317.338 177.66 316.762 177.126 316.41C176.593 316.058 175.788 315.882 174.71 315.882H174.342V314.666H174.726C175.708 314.655 176.449 314.495 176.95 314.186C177.452 313.866 177.702 313.375 177.702 312.714C177.702 312.149 177.516 311.695 177.142 311.354C176.78 311.013 176.257 310.842 175.574 310.842C174.913 310.842 174.38 311.013 173.974 311.354C173.569 311.695 173.329 312.154 173.254 312.73H171.83Z"
                            fill="#636377" />
                        <path
                            d="M233.26 233.41H227.462V239.26H225.772V233.41H220V231.85H225.772V226H227.462V231.85H233.26V233.41Z"
                            fill="#636377" stroke="#ECECEC" strokeWidth="0.5" />
                        <path d="M231.418 271.26V273.108H221.842V271.26H231.418Z" fill="#636377" stroke="#E8E8E8"
                            strokeWidth="0.5" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M224.154 186C224.007 186 223.867 186.065 223.772 186.177L219.118 191.677C218.961 191.863 218.961 192.137 219.118 192.323L223.772 197.823C223.867 197.935 224.007 198 224.154 198H235.19C235.466 198 235.69 197.776 235.69 197.5V186.5C235.69 186.224 235.466 186 235.19 186H224.154ZM223.009 185.531C223.294 185.194 223.713 185 224.154 185H235.19C236.018 185 236.69 185.672 236.69 186.5V197.5C236.69 198.328 236.018 199 235.19 199H224.154C223.713 199 223.294 198.806 223.009 198.469L218.355 192.969C217.882 192.41 217.882 191.59 218.355 191.031L223.009 185.531Z"
                            fill="#636377" />
                        <path
                            d="M231.106 195.2L229.198 193.292L227.29 195.2L226.57 194.48L228.478 192.572L226.606 190.7L227.302 189.992L229.186 191.876L231.07 189.992L231.79 190.712L229.906 192.596L231.802 194.504L231.106 195.2Z"
                            fill="#636377" />
                        <path
                            d="M141.119 114.2V111.88L155.999 92.68H158.999V111.72H163.399V114.2H158.999V121H156.159V114.2H141.119ZM156.359 96.16L144.119 111.72H156.359V96.16Z"
                            fill="#161627" />
                        <path
                            d="M176.256 92.2C178.043 92.2 179.563 92.52 180.816 93.16C182.069 93.7733 183.016 94.6267 183.656 95.72C184.323 96.8133 184.656 98.0533 184.656 99.44C184.656 101.2 184.136 102.693 183.096 103.92C182.056 105.147 180.469 105.867 178.336 106.08V106.32C180.469 106.853 182.136 107.693 183.336 108.84C184.563 109.96 185.176 111.52 185.176 113.52C185.176 115.813 184.416 117.68 182.896 119.12C181.403 120.56 179.216 121.28 176.336 121.28C173.483 121.28 171.176 120.547 169.416 119.08C167.656 117.587 166.603 115.427 166.256 112.6H169.016C169.309 114.493 170.069 116.013 171.296 117.16C172.549 118.28 174.203 118.84 176.256 118.84C178.256 118.84 179.776 118.333 180.816 117.32C181.883 116.307 182.416 114.987 182.416 113.36C182.416 111.44 181.656 109.987 180.136 109C178.643 107.987 176.523 107.48 173.776 107.48H172.896V105.12H173.776C179.189 105.12 181.896 103.32 181.896 99.72C181.896 98.1467 181.389 96.8933 180.376 95.96C179.363 95.0267 177.963 94.56 176.176 94.56C174.416 94.56 172.923 95.0533 171.696 96.04C170.469 97.0267 169.736 98.5067 169.496 100.48H166.736C166.949 97.8667 167.883 95.84 169.536 94.4C171.189 92.9333 173.429 92.2 176.256 92.2Z"
                            fill="#161627" />
                        <path
                            d="M189.494 118.88C194.214 115.76 197.974 112.627 200.774 109.48C203.601 106.333 205.014 103.387 205.014 100.64C205.014 98.9067 204.561 97.5067 203.654 96.44C202.748 95.3467 201.281 94.8 199.254 94.8C197.174 94.8 195.588 95.4533 194.494 96.76C193.428 98.04 192.828 99.7867 192.694 102H189.934C190.121 98.9333 191.068 96.5733 192.774 94.92C194.481 93.24 196.708 92.4 199.454 92.4C201.961 92.4 203.988 93.08 205.534 94.44C207.081 95.8 207.854 97.7867 207.854 100.4C207.854 102.613 207.121 104.88 205.654 107.2C204.188 109.493 202.361 111.627 200.174 113.6C197.988 115.547 195.761 117.2 193.494 118.56H208.654V120.88H189.494V118.88Z"
                            fill="#161627" />
                        <path d="M215.31 117.4V121H211.59V117.4H215.31Z" fill="#161627" />
                        <path
                            d="M219.442 99.52C219.442 98.1067 219.815 96.84 220.562 95.72C221.308 94.6 222.402 93.72 223.842 93.08C225.282 92.44 226.988 92.12 228.962 92.12C230.962 92.12 232.668 92.44 234.082 93.08C235.522 93.72 236.615 94.6 237.362 95.72C238.108 96.84 238.482 98.1067 238.482 99.52C238.482 101.12 238.028 102.507 237.122 103.68C236.242 104.827 234.882 105.64 233.042 106.12C234.988 106.573 236.508 107.44 237.602 108.72C238.695 109.973 239.242 111.547 239.242 113.44C239.242 116.053 238.308 118.12 236.442 119.64C234.602 121.133 232.108 121.88 228.962 121.88C225.842 121.88 223.348 121.133 221.482 119.64C219.615 118.12 218.682 116.053 218.682 113.44C218.682 111.547 219.228 109.973 220.322 108.72C221.415 107.44 222.935 106.573 224.882 106.12C223.042 105.64 221.668 104.827 220.762 103.68C219.882 102.507 219.442 101.12 219.442 99.52ZM222.242 99.88C222.242 101.613 222.842 102.96 224.042 103.92C225.242 104.853 226.882 105.32 228.962 105.32C231.042 105.32 232.682 104.853 233.882 103.92C235.082 102.96 235.682 101.613 235.682 99.88C235.682 98.1467 235.068 96.8133 233.842 95.88C232.642 94.92 231.015 94.44 228.962 94.44C226.935 94.44 225.308 94.92 224.082 95.88C222.855 96.8133 222.242 98.1467 222.242 99.88ZM221.442 113.32C221.442 115.267 222.122 116.787 223.482 117.88C224.842 118.973 226.668 119.52 228.962 119.52C231.255 119.52 233.082 118.973 234.442 117.88C235.802 116.787 236.482 115.267 236.482 113.32C236.482 111.32 235.775 109.827 234.362 108.84C232.948 107.827 231.148 107.32 228.962 107.32C226.775 107.32 224.975 107.827 223.562 108.84C222.148 109.827 221.442 111.32 221.442 113.32Z"
                            fill="#161627" />
                        <path
                            d="M168.469 69.296V67.892L172.525 62.432H174.337V67.832H175.441V69.296H174.337V71H172.633V69.296H168.469ZM172.765 64.412L170.197 67.832H172.765V64.412Z"
                            fill="#636377" />
                        <path
                            d="M179.321 62.204C179.897 62.204 180.389 62.304 180.797 62.504C181.213 62.704 181.525 62.98 181.733 63.332C181.949 63.684 182.057 64.076 182.057 64.508C182.057 65.052 181.909 65.5 181.613 65.852C181.317 66.196 180.925 66.408 180.437 66.488V66.548C181.621 66.9 182.213 67.596 182.213 68.636C182.213 69.364 181.969 69.956 181.481 70.412C180.993 70.86 180.305 71.084 179.417 71.084C178.473 71.084 177.721 70.852 177.161 70.388C176.601 69.916 176.289 69.208 176.225 68.264H177.881C177.921 68.696 178.061 69.04 178.301 69.296C178.549 69.544 178.897 69.668 179.345 69.668C179.729 69.668 180.029 69.564 180.245 69.356C180.461 69.14 180.569 68.86 180.569 68.516C180.569 68.108 180.413 67.796 180.101 67.58C179.789 67.364 179.329 67.256 178.721 67.256H178.373V65.876H178.721C179.841 65.892 180.401 65.504 180.401 64.712C180.401 64.368 180.301 64.1 180.101 63.908C179.901 63.708 179.629 63.608 179.285 63.608C178.925 63.608 178.629 63.724 178.397 63.956C178.165 64.18 178.033 64.496 178.001 64.904H176.345C176.393 64.04 176.673 63.376 177.185 62.912C177.697 62.44 178.409 62.204 179.321 62.204Z"
                            fill="#636377" />
                        <path
                            d="M183.216 69.668C185.928 67.788 187.284 66.228 187.284 64.988C187.284 64.612 187.192 64.316 187.008 64.1C186.832 63.884 186.552 63.776 186.168 63.776C185.376 63.776 184.956 64.316 184.908 65.396H183.264C183.312 64.396 183.616 63.64 184.176 63.128C184.736 62.608 185.444 62.348 186.3 62.348C187.172 62.348 187.84 62.576 188.304 63.032C188.776 63.48 189.012 64.08 189.012 64.832C189.012 65.424 188.836 66.02 188.484 66.62C188.14 67.212 187.7 67.76 187.164 68.264C186.628 68.76 186.064 69.188 185.472 69.548H189.18V70.928H183.216V69.668Z"
                            fill="#636377" />
                        <path d="M191.621 69.236V71H189.797V69.236H191.621Z" fill="#636377" />
                        <path
                            d="M192.677 64.46C192.677 64.052 192.789 63.672 193.013 63.32C193.245 62.968 193.585 62.684 194.033 62.468C194.489 62.252 195.037 62.144 195.677 62.144C196.317 62.144 196.861 62.252 197.309 62.468C197.765 62.684 198.105 62.968 198.329 63.32C198.561 63.672 198.677 64.052 198.677 64.46C198.677 64.924 198.565 65.32 198.341 65.648C198.117 65.968 197.781 66.208 197.333 66.368C197.837 66.56 198.221 66.84 198.485 67.208C198.757 67.568 198.893 68.008 198.893 68.528C198.893 69.088 198.753 69.572 198.473 69.98C198.193 70.38 197.809 70.684 197.321 70.892C196.841 71.1 196.293 71.204 195.677 71.204C195.061 71.204 194.509 71.1 194.021 70.892C193.541 70.684 193.161 70.38 192.881 69.98C192.609 69.572 192.473 69.088 192.473 68.528C192.473 68.008 192.605 67.568 192.869 67.208C193.141 66.84 193.529 66.56 194.033 66.368C193.585 66.208 193.245 65.964 193.013 65.636C192.789 65.308 192.677 64.916 192.677 64.46ZM194.345 64.736C194.345 65.12 194.465 65.416 194.705 65.624C194.945 65.832 195.269 65.936 195.677 65.936C196.093 65.936 196.421 65.832 196.661 65.624C196.901 65.416 197.021 65.12 197.021 64.736C197.021 64.352 196.897 64.056 196.649 63.848C196.409 63.632 196.085 63.524 195.677 63.524C195.277 63.524 194.953 63.632 194.705 63.848C194.465 64.056 194.345 64.352 194.345 64.736ZM194.117 68.432C194.117 68.856 194.257 69.192 194.537 69.44C194.817 69.688 195.197 69.812 195.677 69.812C196.157 69.812 196.537 69.688 196.817 69.44C197.105 69.192 197.249 68.856 197.249 68.432C197.249 68 197.101 67.672 196.805 67.448C196.517 67.216 196.141 67.1 195.677 67.1C195.221 67.1 194.845 67.216 194.549 67.448C194.261 67.672 194.117 68 194.117 68.432Z"
                            fill="#636377" />
                        <path
                            d="M208.331 67.508H206.087V69.788H204.455V67.508H202.21V65.96H204.455V63.668H206.087V65.96H208.331V67.508Z"
                            fill="#636377" />
                        <path d="M211.189 64.112V62.528H214.237V71H212.461V64.112H211.189Z" fill="#636377" />
                        <path
                            d="M215.33 69.668C218.042 67.788 219.398 66.228 219.398 64.988C219.398 64.612 219.306 64.316 219.122 64.1C218.946 63.884 218.666 63.776 218.282 63.776C217.49 63.776 217.07 64.316 217.022 65.396H215.378C215.426 64.396 215.73 63.64 216.29 63.128C216.85 62.608 217.558 62.348 218.414 62.348C219.286 62.348 219.954 62.576 220.418 63.032C220.89 63.48 221.126 64.08 221.126 64.832C221.126 65.424 220.95 66.02 220.598 66.62C220.254 67.212 219.814 67.76 219.278 68.264C218.742 68.76 218.178 69.188 217.586 69.548H221.294V70.928H215.33V69.668Z"
                            fill="#636377" />
                        <path
                            d="M222.03 69.296V67.892L226.086 62.432H227.898V67.832H229.002V69.296H227.898V71H226.194V69.296H222.03ZM226.326 64.412L223.758 67.832H226.326V64.412Z"
                            fill="#636377" />
                        <path d="M231.443 69.236V71H229.619V69.236H231.443Z" fill="#636377" />
                        <path
                            d="M232.498 64.46C232.498 64.052 232.61 63.672 232.834 63.32C233.066 62.968 233.406 62.684 233.854 62.468C234.31 62.252 234.858 62.144 235.498 62.144C236.138 62.144 236.682 62.252 237.13 62.468C237.586 62.684 237.926 62.968 238.15 63.32C238.382 63.672 238.498 64.052 238.498 64.46C238.498 64.924 238.386 65.32 238.162 65.648C237.938 65.968 237.602 66.208 237.154 66.368C237.658 66.56 238.042 66.84 238.306 67.208C238.578 67.568 238.714 68.008 238.714 68.528C238.714 69.088 238.574 69.572 238.294 69.98C238.014 70.38 237.63 70.684 237.142 70.892C236.662 71.1 236.114 71.204 235.498 71.204C234.882 71.204 234.33 71.1 233.842 70.892C233.362 70.684 232.982 70.38 232.702 69.98C232.43 69.572 232.294 69.088 232.294 68.528C232.294 68.008 232.426 67.568 232.69 67.208C232.962 66.84 233.35 66.56 233.854 66.368C233.406 66.208 233.066 65.964 232.834 65.636C232.61 65.308 232.498 64.916 232.498 64.46ZM234.166 64.736C234.166 65.12 234.286 65.416 234.526 65.624C234.766 65.832 235.09 65.936 235.498 65.936C235.914 65.936 236.242 65.832 236.482 65.624C236.722 65.416 236.842 65.12 236.842 64.736C236.842 64.352 236.718 64.056 236.47 63.848C236.23 63.632 235.906 63.524 235.498 63.524C235.098 63.524 234.774 63.632 234.526 63.848C234.286 64.056 234.166 64.352 234.166 64.736ZM233.938 68.432C233.938 68.856 234.078 69.192 234.358 69.44C234.638 69.688 235.018 69.812 235.498 69.812C235.978 69.812 236.358 69.688 236.638 69.44C236.926 69.192 237.07 68.856 237.07 68.432C237.07 68 236.922 67.672 236.626 67.448C236.338 67.216 235.962 67.1 235.498 67.1C235.042 67.1 234.666 67.216 234.37 67.448C234.082 67.672 233.938 68 233.938 68.432Z"
                            fill="#636377" />
                        <path
                            d="M168.469 69.296V67.892L172.525 62.432H174.337V67.832H175.441V69.296H174.337V71H172.633V69.296H168.469ZM172.765 64.412L170.197 67.832H172.765V64.412Z"
                            stroke="#FAFAFA" strokeWidth="0.5" />
                        <path
                            d="M179.321 62.204C179.897 62.204 180.389 62.304 180.797 62.504C181.213 62.704 181.525 62.98 181.733 63.332C181.949 63.684 182.057 64.076 182.057 64.508C182.057 65.052 181.909 65.5 181.613 65.852C181.317 66.196 180.925 66.408 180.437 66.488V66.548C181.621 66.9 182.213 67.596 182.213 68.636C182.213 69.364 181.969 69.956 181.481 70.412C180.993 70.86 180.305 71.084 179.417 71.084C178.473 71.084 177.721 70.852 177.161 70.388C176.601 69.916 176.289 69.208 176.225 68.264H177.881C177.921 68.696 178.061 69.04 178.301 69.296C178.549 69.544 178.897 69.668 179.345 69.668C179.729 69.668 180.029 69.564 180.245 69.356C180.461 69.14 180.569 68.86 180.569 68.516C180.569 68.108 180.413 67.796 180.101 67.58C179.789 67.364 179.329 67.256 178.721 67.256H178.373V65.876H178.721C179.841 65.892 180.401 65.504 180.401 64.712C180.401 64.368 180.301 64.1 180.101 63.908C179.901 63.708 179.629 63.608 179.285 63.608C178.925 63.608 178.629 63.724 178.397 63.956C178.165 64.18 178.033 64.496 178.001 64.904H176.345C176.393 64.04 176.673 63.376 177.185 62.912C177.697 62.44 178.409 62.204 179.321 62.204Z"
                            stroke="#FAFAFA" strokeWidth="0.5" />
                        <path
                            d="M183.216 69.668C185.928 67.788 187.284 66.228 187.284 64.988C187.284 64.612 187.192 64.316 187.008 64.1C186.832 63.884 186.552 63.776 186.168 63.776C185.376 63.776 184.956 64.316 184.908 65.396H183.264C183.312 64.396 183.616 63.64 184.176 63.128C184.736 62.608 185.444 62.348 186.3 62.348C187.172 62.348 187.84 62.576 188.304 63.032C188.776 63.48 189.012 64.08 189.012 64.832C189.012 65.424 188.836 66.02 188.484 66.62C188.14 67.212 187.7 67.76 187.164 68.264C186.628 68.76 186.064 69.188 185.472 69.548H189.18V70.928H183.216V69.668Z"
                            stroke="#FAFAFA" strokeWidth="0.5" />
                        <path d="M191.621 69.236V71H189.797V69.236H191.621Z" stroke="#FAFAFA" strokeWidth="0.5" />
                        <path
                            d="M192.677 64.46C192.677 64.052 192.789 63.672 193.013 63.32C193.245 62.968 193.585 62.684 194.033 62.468C194.489 62.252 195.037 62.144 195.677 62.144C196.317 62.144 196.861 62.252 197.309 62.468C197.765 62.684 198.105 62.968 198.329 63.32C198.561 63.672 198.677 64.052 198.677 64.46C198.677 64.924 198.565 65.32 198.341 65.648C198.117 65.968 197.781 66.208 197.333 66.368C197.837 66.56 198.221 66.84 198.485 67.208C198.757 67.568 198.893 68.008 198.893 68.528C198.893 69.088 198.753 69.572 198.473 69.98C198.193 70.38 197.809 70.684 197.321 70.892C196.841 71.1 196.293 71.204 195.677 71.204C195.061 71.204 194.509 71.1 194.021 70.892C193.541 70.684 193.161 70.38 192.881 69.98C192.609 69.572 192.473 69.088 192.473 68.528C192.473 68.008 192.605 67.568 192.869 67.208C193.141 66.84 193.529 66.56 194.033 66.368C193.585 66.208 193.245 65.964 193.013 65.636C192.789 65.308 192.677 64.916 192.677 64.46ZM194.345 64.736C194.345 65.12 194.465 65.416 194.705 65.624C194.945 65.832 195.269 65.936 195.677 65.936C196.093 65.936 196.421 65.832 196.661 65.624C196.901 65.416 197.021 65.12 197.021 64.736C197.021 64.352 196.897 64.056 196.649 63.848C196.409 63.632 196.085 63.524 195.677 63.524C195.277 63.524 194.953 63.632 194.705 63.848C194.465 64.056 194.345 64.352 194.345 64.736ZM194.117 68.432C194.117 68.856 194.257 69.192 194.537 69.44C194.817 69.688 195.197 69.812 195.677 69.812C196.157 69.812 196.537 69.688 196.817 69.44C197.105 69.192 197.249 68.856 197.249 68.432C197.249 68 197.101 67.672 196.805 67.448C196.517 67.216 196.141 67.1 195.677 67.1C195.221 67.1 194.845 67.216 194.549 67.448C194.261 67.672 194.117 68 194.117 68.432Z"
                            stroke="#FAFAFA" strokeWidth="0.5" />
                        <path
                            d="M208.331 67.508H206.087V69.788H204.455V67.508H202.21V65.96H204.455V63.668H206.087V65.96H208.331V67.508Z"
                            stroke="#FAFAFA" strokeWidth="0.5" />
                        <path d="M211.189 64.112V62.528H214.237V71H212.461V64.112H211.189Z" stroke="#FAFAFA" strokeWidth="0.5" />
                        <path
                            d="M215.33 69.668C218.042 67.788 219.398 66.228 219.398 64.988C219.398 64.612 219.306 64.316 219.122 64.1C218.946 63.884 218.666 63.776 218.282 63.776C217.49 63.776 217.07 64.316 217.022 65.396H215.378C215.426 64.396 215.73 63.64 216.29 63.128C216.85 62.608 217.558 62.348 218.414 62.348C219.286 62.348 219.954 62.576 220.418 63.032C220.89 63.48 221.126 64.08 221.126 64.832C221.126 65.424 220.95 66.02 220.598 66.62C220.254 67.212 219.814 67.76 219.278 68.264C218.742 68.76 218.178 69.188 217.586 69.548H221.294V70.928H215.33V69.668Z"
                            stroke="#FAFAFA" strokeWidth="0.5" />
                        <path
                            d="M222.03 69.296V67.892L226.086 62.432H227.898V67.832H229.002V69.296H227.898V71H226.194V69.296H222.03ZM226.326 64.412L223.758 67.832H226.326V64.412Z"
                            stroke="#FAFAFA" strokeWidth="0.5" />
                        <path d="M231.443 69.236V71H229.619V69.236H231.443Z" stroke="#FAFAFA" strokeWidth="0.5" />
                        <path
                            d="M232.498 64.46C232.498 64.052 232.61 63.672 232.834 63.32C233.066 62.968 233.406 62.684 233.854 62.468C234.31 62.252 234.858 62.144 235.498 62.144C236.138 62.144 236.682 62.252 237.13 62.468C237.586 62.684 237.926 62.968 238.15 63.32C238.382 63.672 238.498 64.052 238.498 64.46C238.498 64.924 238.386 65.32 238.162 65.648C237.938 65.968 237.602 66.208 237.154 66.368C237.658 66.56 238.042 66.84 238.306 67.208C238.578 67.568 238.714 68.008 238.714 68.528C238.714 69.088 238.574 69.572 238.294 69.98C238.014 70.38 237.63 70.684 237.142 70.892C236.662 71.1 236.114 71.204 235.498 71.204C234.882 71.204 234.33 71.1 233.842 70.892C233.362 70.684 232.982 70.38 232.702 69.98C232.43 69.572 232.294 69.088 232.294 68.528C232.294 68.008 232.426 67.568 232.69 67.208C232.962 66.84 233.35 66.56 233.854 66.368C233.406 66.208 233.066 65.964 232.834 65.636C232.61 65.308 232.498 64.916 232.498 64.46ZM234.166 64.736C234.166 65.12 234.286 65.416 234.526 65.624C234.766 65.832 235.09 65.936 235.498 65.936C235.914 65.936 236.242 65.832 236.482 65.624C236.722 65.416 236.842 65.12 236.842 64.736C236.842 64.352 236.718 64.056 236.47 63.848C236.23 63.632 235.906 63.524 235.498 63.524C235.098 63.524 234.774 63.632 234.526 63.848C234.286 64.056 234.166 64.352 234.166 64.736ZM233.938 68.432C233.938 68.856 234.078 69.192 234.358 69.44C234.638 69.688 235.018 69.812 235.498 69.812C235.978 69.812 236.358 69.688 236.638 69.44C236.926 69.192 237.07 68.856 237.07 68.432C237.07 68 236.922 67.672 236.626 67.448C236.338 67.216 235.962 67.1 235.498 67.1C235.042 67.1 234.666 67.216 234.37 67.448C234.082 67.672 233.938 68 233.938 68.432Z"
                            stroke="#FAFAFA" strokeWidth="0.5" />
                        <defs>
                            <filter id="filter0_bd_182_456" x="0" y="-4" width="297" height="437" filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImage" stdDeviation="17" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_182_456" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="17" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix"
                                values="0 0 0 0 0.678431 0 0 0 0 0.168627 0 0 0 0 0.0431373 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="effect1_backgroundBlur_182_456" result="effect2_dropShadow_182_456" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_182_456" result="shape" />
                            </filter>
                            <linearGradient id="paint0_linear_182_456" x1="149" y1="119" x2="149" y2="254"
                            gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.79" />
                            <stop offset="1" stopColor="white" stopOpacity="0.13" />
                            </linearGradient>
                        </defs>
                        </svg>
                    </div>
                    </div>
                </div>
                </div>
            </section>


    )
}