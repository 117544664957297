import NProgress from "nprogress";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      NProgress.start();

    
      if (authToken) {
        config.headers.Authorization = `Token ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(function (response) {
    NProgress.done();
    return response;
  }, function (error) {
    console.error(error)
    NProgress.done();
    return Promise.reject(error);
  });
}


