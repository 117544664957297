import React, {lazy} from "react";

import { Switch, Route, Link, Router } from "react-router-dom";
import { createBrowserHistory } from 'history';

import Home from "./App/home";
import Invoice from "./App/invoice";
import Drinks from "./App/drinks";


// const Drinks = lazy(() =>
//   import("./App/drinks")
// );

function MainApp() {
  const history = createBrowserHistory();
        


  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/checkout' component={Invoice} />
      <Route path='/drinks' component={Drinks} />
    </Switch>
  );
}

export default MainApp;
