import React, {useEffect, useState} from "react";
import { Redirect, Switch, Route, Router, Link, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
//  import {Layout} from "../_metronic/layout";
import MainApp from "./";
import { createBrowserHistory } from "history"
// import { Logout, AuthPage } from "./Auth";
//  import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
// import AppPage from "./App/app"
// import { login, resend_confirmation } from "./Auth/_redux/authCrud";
// import * as auth_actions from "./Auth/_redux/authRedux";


export function RoutesStack() {

    return (
        <Switch>
            <Route>
                <MainApp />
             </Route>
        </Switch>



    );
}


