import React, { useState, useEffect, useRef } from "react"

import Header from "../../../components/header";
import Footer from "../../../components/footer";
import SVG_Header from "../../../components/svg_header";
import Category_List from "../../../components/cat_list";

import { login, register, getUserByToken } from "../../_redux/auth/authCrud";
import * as auth_actions from "../../_redux/auth/authRedux";

import Modal from 'bootstrap/js/dist/modal';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/app/Actions";
import config from "../../../config";
import { useHistory } from "react-router-dom";
import * as functions from "../../../global/functions";

import { toast } from 'react-toastify';


import $ from 'jquery';
import 'jquery-ui-bundle';


export default function Home(props) {
  
  
  const TIME_OUT = 1000;
  const dispatch = useDispatch();
  const history = useHistory();

  const modRef = useRef();

  const [ema, setEmail] = useState('');
  const [password, setPass] = useState('');
  const [err, setError] = useState('');
  const [action_load, setLoad] = useState(false);
 
  const [ev, setEventType] = useState('');
  const [g, setGuestNo] = useState(100);
  const [b, setBudget] = useState('');
  const [load, setLoading] = useState(false);

  const [calculated, set_cal] = useState(false);
  const [mode, set_mode] = useState('login');

  const amounts = ["200000", "500000", "1000000", "2000000", "5000000"]

  // const [string_budget, setStringBudget] = useState('');
  
  // const [total_estimate, setTotalEst] = useState('0');
  // const [drinks_est, setDrinksEst] = useState('0');
  // const [services_est, setServiceEst] = useState('0');
  

 

  const { auth, categories, sel, last_name, email, tel, event_type, guests_no, budget, listLoading, actionsLoading } = useSelector(
    (state) => ({ 
      auth: state.auth.authToken && state.auth.user,
      sel: state.app.selected,
      last_name: state.app.last_name,
      email: state.app.email,
      tel: state.app.tel,
      event_type: state.app.event_type,
      guests_no: state.app.guests_no,
      budget: state.app.budget,
      categories: state.app.categories,
      listLoading: state.app.listLoading,
      actionsLoading: state.app.actionsLoading
    }),
    shallowEqual
  );



  const [selected, setSelected] = useState([]);
  // const [selected, setSelected] = useState(!Array.isArray(sel) ? JSON.parse(sel) : []);
 


  const showModal = () => {
      const modalEle = modRef.current
      const bsModal = new Modal(modalEle)
      bsModal.show()
  }

  const hideModal = () => {
      const modalEle = modRef.current
      const bsModal= Modal.getInstance(modalEle)
      bsModal.hide()
  }



  useEffect(() => {
    // alert("here");
    if (selected.length === 0) {
        init_cats();
    }

    // showModal();

  }, [])


  useEffect(() => {

    $(".select-control").selectmenu(({ change: function( event, ui ) { 
      let val = ui.item.value
      setGuestNo(val);
      recalibrate(val);
      set_cal(false);
      dispatch(actions.write_data('guests', val))
     }}));

    $(".select-control2").selectmenu(({ change: function( event, ui ) { 
      let val = ui.item.value
      setEventType(val)
      dispatch(actions.write_data('event_type', val))
     }}));

    // if (!categories) {
    dispatch(actions.fetchAllData('categories', ''))
    // }

  }, [])


  function calculate() {
    if (budget === '') {
      toast.error(`Error: Input your budget`);
      return;
    }
    setLoading(true);
    recalibrate(guests_no);
    setTimeout(() => {
      set_cal(true);
      setLoading(false);
    }, TIME_OUT)
  }




  function currencyFormat(num) {
    if (!isNaN(num)){
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return 0;
    }
 }



 function recalibrate(value) {
  // console.log(selected.length)

  var selected_prods = [];

  selected.forEach((ele) => {
    var obj = {};
  
    obj['amount'] = (budget < config.threshold_amount) && config.threshold_categories.includes(ele.name) ? 0 : functions.getAmount(ele.name, value);
    obj['name'] = functions.reformat_name(ele.name);

    if ( (budget < config.threshold_amount) && config.threshold_categories.includes(ele.name)) {
       return;
    }
    selected_prods.push(obj)
   
  })


  setSelected([...selected_prods])
               

 }


 function logData(e) {
      e.preventDefault();
      setLoad(true);
      setError('');
      sendLogin();
 }

 function regData(e) {
    e.preventDefault();
    setLoad(true);
    setError('');
    register(ema, password)
        .then((response) => {
          var data = response.data;
          if (response.status === 201) {
            sendLogin();
          } else {
            setError(`Error occured while signing up, try again`)
          }
      })
      .catch((error) => {
        console.log("errr", error)
        var message = error.response.data.email.join();
        setLoad(false);
        setError(`${message}`)
    });
}

 function sendLogin() {
    login(ema, password)
        .then((response) => {
          var data = response.data;
          if (response.status === 200) {
            dispatch(auth_actions.actions.login(data.auth_token));
            getUserByToken(data.auth_token).then((resp) => {
              setLoad(false);
              dispatch(auth_actions.actions.fulfillUser(resp.data));

              hideModal();
              window.location.href = '/drinks';
                              
              // props.history.push({
              //   pathname: '/drinks',
              //   state: {
              //     categories: selected
              //   }
              // })

            }).catch((err) => {
              var errdd = err.response.data.non_field_errors.join();
              setLoad(false);
              setError(`${errdd}`)
            })
            
          } else {
            setError(`Error occured while logging in, try again`)
          }
      })
      .catch((error) => {
        console.log("errr", error)
        var message = error.response.data.non_field_errors.join();
        setLoad(false);
        setError(`${message}`)
    });
 }


 function init_cats() {
  // setActive();

    var selected_prods = selected;

      
    // calculate for cognac
    var con = {};
    con['amount'] = functions.getAmount('cognac', guests_no);
    con['name'] = functions.reformat_name('cognac');
    selected_prods.push(con)

    // calculate for champagne
    var cham = {};
    cham['amount'] = functions.getAmount('champagne', guests_no);
    cham['name'] = functions.reformat_name('champagne');
    selected_prods.push(cham)

    // calculate for whiskey
    var whis = {};
    whis['amount'] = functions.getAmount('whisky', guests_no);
    whis['name'] = functions.reformat_name('whisky');
    selected_prods.push(whis)
    // console.log("wizkid", whis);

    // calculate for wines
    var wines = {};
    wines['amount'] = functions.getAmount('wines', guests_no);
    wines['name'] = functions.reformat_name('wines');
    selected_prods.push(wines)

    // calculate for wines
    var spark = {};
    spark['amount'] = functions.getAmount('sparkling wines', guests_no);
    spark['name'] = functions.reformat_name('sparkling wines');
    selected_prods.push(spark)

    // calculate for beer
    var beer = {};
    beer['amount'] = functions.getAmount('beer & ciders', guests_no);
    beer['name'] = functions.reformat_name('beer & ciders');
    selected_prods.push(beer)
    
    // calculate for water
    var water = {};
    water['amount'] = functions.getAmount('water', guests_no);
    water['name'] = functions.reformat_name('water');
    selected_prods.push(water)


    // calculate for juice
    var juice = {};
    juice['amount'] = functions.getAmount('fruit juice & yoghurt', guests_no);
    juice['name'] = functions.reformat_name('fruit juice & yoghurt');
    selected_prods.push(juice)


    // calculate for soft drink
    var soft = {};
    soft['amount'] = functions.getAmount('soft drinks', guests_no);
    soft['name'] = functions.reformat_name('soft drinks');
    selected_prods.push(soft)

    // calculate for energy drink
    var soft = {};
    soft['amount'] = functions.getAmount('energy & sport drinks', guests_no);
    soft['name'] = functions.reformat_name('energy & sport drinks');
    selected_prods.push(soft)


    // calculate for vodka
    var vodka = {};
    vodka['amount'] = functions.getAmount('vodka', guests_no);
    vodka['name'] = functions.reformat_name('vodka');
    selected_prods.push(vodka)

    // calculate for tequila
    var tequila = {};
    tequila['amount'] = functions.getAmount('tequila', guests_no);
    tequila['name'] = functions.reformat_name('tequila');
    selected_prods.push(tequila)


    // calculate for gin
    var gin = {};
    gin['amount'] = functions.getAmount('gin', guests_no);
    gin['name'] = functions.reformat_name('gin');
    selected_prods.push(gin)

    // setSelected(selected_prods)
    setSelected([...selected_prods])


 }


 function calibrate(name) {

  var selected_prods = selected;

  // console.log(selected_prods);

  var obj = {};
  obj['amount'] = functions.getAmount(name, guests_no);
  obj['name'] = functions.reformat_name(name);
  selected_prods.push(obj)
 
  setSelected([...selected_prods])
  
 }





 function action(name, action) {
  var arr = selected;
  let obj = arr.find(x => x.name === name);
  let index = arr.indexOf(obj);


  
  if (index !== -1) {
  
    
      if (action === 'del') {
        arr.splice(index, 1); 
        setSelected([...arr])
        return;
      }

      var am = arr[index].amount;
      if (am === 1) {
        if (action === 'rem') {
          arr.splice(index, 1); 
        } else {
          arr[index].amount++;
        }
      } else {
        if (action === 'rem') {
          arr[index].amount--;
        } else {
          arr[index].amount++;
        }
      }
  } else {
    if (action === 'add') {
      var objd = {};
      objd['amount'] = 1;
      objd['name'] = name;
      arr.push(objd)
    }
  }

  
  setSelected([...arr])
  

 }


 useEffect(() => {
  dispatch(actions.write_data('selected', JSON.stringify(selected)))
 }, [selected]) 


 function getAm(name) {
  var amount = 0;
  let obj = selected.find(x => x.name === name);
  let index = selected.indexOf(obj);

  if (obj) {
      amount = selected[index].amount;
  }

  return amount;
 }

    return (
        <React.Fragment>
             <main className="page-calculator">
              <Header/>



            <SVG_Header/>
  


      <section class="--calculation-form">
        <div class="container">
          <div class="row">
            <form class="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0">
              <div class="--calculation-container">
                <div class="row">

            

                  <div class="col-12" style = {{marginBottom: 40}}>
                    <div id="carouselPromo" class="carousel slide" data-bs-ride="true">

                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img src="assets/images/promo/img-1.jpg" class="d-block w-100" alt="..."/>
                        </div>
                        <div class="carousel-item">
                          <img src="assets/images/promo/img-1.jpg" class="d-block w-100" alt="..."/>
                        </div>
                        <div class="carousel-item">
                          <img src="assets/images/promo/img-1.jpg" class="d-block w-100" alt="..."/>
                        </div>
                      </div>

                      <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselPromo" data-bs-slide-to="0" class="active"
                          aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselPromo" data-bs-slide-to="1"
                          aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselPromo" data-bs-slide-to="2"
                          aria-label="Slide 3"></button>
                      </div>

                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 mb-xxl-4 mb-xl-4 mb-lg-4 mb-md-4 mb-3">
                    <label for="guestFormControlSelect" className="form-label"> Number of Guest</label>
                    <select 
                    value = {guests_no}
                    className="form-control select-control">
                    <option value = {100}> 50 - 100</option>
                    <option value = {200}> 100 - 200 </option>
                    <option value = {400}> 200 - 400 </option>
                    <option value = {500}> 400 - 500 </option>
                    <option value = {700}> 500 - 700 </option>
                    <option value = {1000}> 700 - 1000 </option>
                    </select>
                </div>
                <div className="col-lg-6 col-md-6 mb-xxl-4 mb-xl-4 mb-lg-4 mb-md-4 mb-3">
                    <label for="eventTypeFormControlSelect" className="form-label"> Type of event</label>
                    <select name="eventType"
                    value = {event_type}
                    className="form-control select-control2">
                    <option> Wedding Reception </option>
                    <option> Birthday Party</option>
                    <option>Conference </option>
                    <option>Get Together </option>
                    <option>Corporate Party </option>
                    <option>Engagement </option>
                    <option>Wedding Anniversary </option>
                    <option>Pool Party </option>
                    <option>Beach Party </option>
                    <option>Award Show </option>
                    <option>Bachelor Party </option>
                    <option>House warming </option>
                    </select>
                </div>
                <div className="col-12 col-md-12">
                    <div className="--budget-cont mb-5 mt-1">
                    <label for="phoneFormControlInput" className="form-label">Budget</label>
                    <div className="--budget-cont-input">

                    <span className="--input-group-text" id="inputGroupPrepend2">₦</span>
                    <input 
                      className="form-control lg"
                      onBlur={(e) => {
                        var value = e.target.value;
                        const number = value.replace(/\$|,|\./g, "");
                        const def_num = 100000;
                        if (number < def_num) {
                          setBudget(def_num);
                          set_cal(false);
                          dispatch(actions.write_data('budget', def_num))
                          toast.error(`Error: Minimum amount of ₦100,000 is required`);
                        }
                      }}
                      onChange = {(e) => {
                        var value = e.target.value;
                        const number = value.replace(/\$|,|\./g, "");
                        if (number.length <= 21) {
                          setBudget(number);
                          set_cal(false);
                          dispatch(actions.write_data('budget', number))
                        }
                        //  else {
                        //   setBudget('0');
                        //   dispatch(actions.write_data('budget', '0'))
                        // }
                      }}
                      value={currencyFormat(Number(budget))} 
                    />
                    <span className="--input-group-text" id="inputGroupPrepend2">00</span>

                    </div>
                    <ul class="--budget-cont-price-list">
                      {
                        amounts.map((am) => {
                          return (
                            <li onClick={() => {
                              setBudget(am);
                              set_cal(false);
                              dispatch(actions.write_data('budget', am))
                            }}><span class = {`${budget === am ? 'active' : ''}`}>{`₦${functions.new_number_format(am)}`}</span></li>
                          )   
                        })
                      }
                    </ul>
                    </div>
                </div>

                
                  <div class="col-12 mb-lg-4 mb-md-4 mb-0 mt-0">
                    <div class="d-grid">
                      <a href = "javascript:;" onClick={() => calculate()} class="btn btn-secondary">Calculate</a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

    {budget === '' || !calculated ? 

    <section class="--estmate-empty-state">
      <div class="container">
        <div class="row">
          <div class="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0">
            <div class="--estmate-empty-state__holder">
              <div class="--estmate-empty-state__cont text-center">
                <img src="assets/images/empty-states/empty-calculator.svg" alt="" class="img-fluid" />
                <h4 class="title">Display Calculation</h4>
                <p>To see a price estimate for your event, <br />enter the information above.</p>
                {load &&
                <div class="infinite-scroll-display">
                  <div class="infinite-scroll-display__loading-more">
                    <span class="loading-icon">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </span>
                    <p>Loading Estimate</p>
                  </div>
                </div>}
              </div>
            </div>
          </div>
          </div>
          </div>
    </section>
    :

  
    <section className="--estmate-calculation">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0">

                <div className="--main-cont">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="title">Select Your Category</h5>
                    </div>

                    <div className="col-12">
                      <ul className="--category-list">

                      {
                        config.categories && config.categories.map((ele, i) => {
                            var am = getAm(ele);
                            return (
                              <Category_List
                              selected = {selected}
                              calibrate = {calibrate}
                              current_amount = {getAm(ele)}
                              action = {action}
                              key = {i} name = {ele}/>
                          )
                        
                        })
                      }


                    </ul>
                  </div>

                  {/* <div className="col-12">
                    <div className="--total-cont row">
                      <div className="col-12">
                        <hr className="lg" />
                      </div>
                      <h6 className="title col-6 justify-content-center">Total Estimate</h6>
                      <h3 className="title col-6 total text-end">{number_format(total_estimate)}</h3>
                    </div>
                  </div>

                  <div className="col-12">
                    <hr />
                  </div> */}

                  {/* <div className="col-12">
                    <div className="--breakdown-total-cont row">
                      <h6 className="title col-6">Estimate for Drinks</h6>
                      <h6 className="title col-6 total text-end">{number_format(drinks_est)}</h6>

                      <h6 className="title col-6">Estimate for Services</h6>
                      <h6 className="title col-6 total text-end">{number_format(services_est)}</h6>

                    </div>
                  </div> */}

                  <div className="col-12">
                    <div className="--result-action">
                    <div className="--result-action-cont row">
                      <div className="col-lg-8 col-md-10 --result-actioncont-holder">
                        <h4 className="title">Do you like the estimated result?</h4>
                        <p>A few more steps left! Continue to purchase the suggested Products or send yourself or a friend an email with the suggested products. </p>
                      </div>
                      <div className="col-lg-4">
                        <div className="--btn-cont d-grid">
                          <button 
                          disabled = {
                            selected.length === 0 || 
                            // first_name === '' ||
                            // last_name === '' ||
                            // email === '' ||
                            // tel === '' ||
                            budget === ''
                          }
                          onClick={() => {
                            if (!auth) {
                              showModal();
                            } else {
                              window.location.href = '/drinks';
                              // props.history.push({
                              //   pathname: '/drinks',
                              //   state: {
                              //     categories: selected
                              //   }
                              // })
                            }
                            
                            
                          }}
                          className="btn btn-success">Continue </button>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    }


    <Footer/>



    <div class="modal fade signup" ref = {modRef} id="signup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">

            <div class="d-flex flex-wrap align-items-stretch">

              <div class="col-lg-5 col-md-6 col-sm-12 col-12 d-flex align-items-center info-section">
                <div class="calculator-info text-center">
                  <h4 class="title main">Knowing the amount of drinks needed for your events has just been.</h4>
                  <div id="signup-calc-info" class="carousel slide" data-bs-ride="true">

                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div class="img-cont"><img src="assets/images/calculator/calc-signup/img-1.png" class="img-fluid"
                            alt="..."/></div>
                        <h6 class="title">Select exact products for your event</h6>
                        <p>Knowing the amount of drinks needed for your events has just been.</p>
                      </div>
                      <div class="carousel-item">
                        <div class="img-cont"><img src="assets/images/calculator/calc-signup/img-1.png" class="img-fluid"
                            alt="..."/></div>
                        <h6 class="title">Select exact products for your event</h6>
                        <p>Knowing the amount of drinks needed for your events has just been.</p>
                      </div>
                      <div class="carousel-item">
                        <div class="img-cont"><img src="assets/images/calculator/calc-signup/img-1.png" class="img-fluid"
                            alt="..."/></div>
                        <h6 class="title">Select exact products for your event</h6>
                        <p>Knowing the amount of drinks needed for your events has just been.</p>
                      </div>
                    </div>

                    <div class="carousel-indicators">
                      <button type="button" data-bs-target="#signup-calc-info" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#signup-calc-info" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#signup-calc-info" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-lg-7 col-md-6 col-sm-12 col-12 form-info tab-content" id="signup-tabContent">
                <div class="login tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab" tabindex="0">

                  <div class="text-end">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
  
                  <h5 class="title">{mode === 'login' ? 'Login' : 'Signup'} with your details</h5>
                  <p><small>Determining the amount alcohol needed for an event is tricky.</small> </p>
                  <form class="row login" onSubmit={mode === 'login' ? logData : regData}>
                    {err &&
                      <div class="alert alert-danger" role="alert">
                      {err}
                    </div>
                    }
                    <div class="col-12 mb-3 mt-2">
                      <label for="femailFormControlInput" class="form-label">Email</label>
                      <input 
                        value = {ema}
                        required
                        onChange = {(e) => setEmail(e.target.value)}
                        type="text" class="form-control" placeholder="Enter your email" />
                    </div>
                    <div class="col-12 mb-3 mt-2">
                      <label for="femailFormControlInput" class="form-label inline-label"><span
                          class="label">Password</span> 
                          {/* <a href="" class="forgot extra">Forgot Password?</a> */}
                          </label>
                      <input type="password" 
                        value = {password}
                        required
                        onChange = {(e) => setPass(e.target.value)}
                        class="form-control" placeholder="Enter your password" />
                    </div>
                    <div class="col-12 mt-3 mb-4 d-grid">
                      {mode === 'login' ?
                          <button disabled = {action_load} type = "submit" class="btn btn-secondary">{action_load ? 'Please wait...' : 'login to Proceed'}</button>
                          :
                          <button disabled = {action_load} type = "submit" class="btn btn-secondary">{action_load ? 'Please wait...' : 'signup to Proceed'}</button>
                      }
                    </div>
                    <div class="col-12 text-center">
                      <p>
                        {
                          mode === 'login' ?

                          <small>You don't have an account with Drinks.ng? <a href="javascript:;" onClick={() => set_mode('register')}>Create now</a></small>
                          
                          : 

                          <small>You already have an account with Drinks.ng? <a href="javascript:;" onClick={() => set_mode('login')}>Login here</a></small>
                        }
                        </p>
                    </div>
                    {/* <div class="col-12 mb-3">
                      <div class="alt-divider text-center"><span>OR</span></div>
                    </div> */}
                  </form>
                  {/* <div class="col-12">
                    <div class="alt-login">
                      <ul>
                        <li><a href="" class="btn btn-outline"></a></li>
                        <li><a href="" class="btn btn-outline"></a></li>
                        <li><a href="" class="btn btn-outline"></a></li>
                      </ul>
                    </div>
                  </div> */}

                </div>

                <div class="signup tab-pane fade" id="pills-signup" role="tabpanel" aria-labelledby="pills-signup-tab" tabindex="0">

                  <div class="text-end">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
  
                  <h5 class="title">Signup with your details</h5>
                  <p><small>Determining the amount alcohol needed for an event is tricky.</small> </p>
                  <form class="row login">
                    <div class="col-12 mb-3 mt-2">
                      <label for="femailFormControlInput" class="form-label">Email</label>
                      <input type="text" class="form-control" placeholder="Enter your email" />
                    </div>
                    <div class="col-12 mb-3 mt-2">
                      <label for="femailFormControlInput" class="form-label inline-label"><span
                          class="label">Password</span> <a href="" class="forgot extra">Forgot Password?</a></label>
                      <input type="password" class="form-control" placeholder="Enter your password" />
                    </div>
                    <div class="col-12 mt-3 mb-4 d-grid">
                      <button class="btn btn-secondary">signup to Proceed</button>
                    </div>
                    <div class="col-12 text-center">
                      <p><small>Already have an account with Drinks.ng? <a href="">Login</a></small></p>
                    </div>
                    {/* <div class="col-12 mb-3">
                      <div class="alt-divider text-center"><span>OR</span></div>
                    </div> */}
                  </form>
                  {/* <div class="col-12">
                    <div class="alt-login">
                      <ul>
                        <li><a href="" class="btn btn-outline"></a></li>
                        <li><a href="" class="btn btn-outline"></a></li>
                        <li><a href="" class="btn btn-outline"></a></li>
                      </ul>
                    </div>
                  </div> */}

                </div>

                <div class="mobile-access tab-pane fade" id="pills-mobile-access" role="tabpanel" aria-labelledby="pills--mobile-access-tab" tabindex="0">

                  <div class="d-grid gap-3">
                    <a href="#pills-login" class="btn btn-outline mobile-access-btn" data-bs-target="#pills-login">Login</a>
                    <a href="#pills-signup" class="btn btn-success mobile-access-btn" data-bs-target="#pills-signup">Sign up</a>
                  </div>
                </div>
                

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>


  </main>





        </React.Fragment>
    )
}
